import React, {useMemo} from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Checkbox, Link, Switch, Typography} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import {
    findProfit,
    findTotal
} from "../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyItem/OrderChangeTableBodyItem";



const JuiceProductionList = ({statusBackground, selectedItems, allItemsArray, handleTopLevelSelectAlone, handleAllItemsSelect, setSwitchChecker, switchChecker, rows, openRows, toggleRowOpen, handleNestedSelect}) => {


    const findTotal = (marketPrice, ourPrice, IVA, quantity) => {

        const total = marketPrice * quantity

        return !isNaN(total) ? total.toFixed(2) : '0.00'
    }


    const findProfit = (marketPrice, ourPrice, IVA, quantity) => {
        if (isNaN(marketPrice)) {
            return '0.00'
        }

        const revenue = marketPrice * quantity
        const cost = ourPrice * quantity

        const profit = revenue - cost

        return profit.toFixed(2)
    }




    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                // Checked if all nested orders are selected
                                checked={selectedItems.length == allItemsArray.length}
                                onChange={(event) => handleAllItemsSelect()}
                            />
                        </TableCell>
                        <TableCell>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'center',
                                justifyContent: 'center',
                                textAlign: 'left'
                            }}>
                                Group
                                <Switch checked={switchChecker} onChange={(event) => {
                                    setSwitchChecker(prev => !prev)
                                }}/>

                            </div>
                        </TableCell>
                        <TableCell>Elemento</TableCell>
                        <TableCell align="right">Order</TableCell>
                        <TableCell align="right">Unit</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="right">COGS</TableCell>
                        <TableCell align="right">Profit</TableCell>
                        <TableCell align="right">Delivery</TableCell>
                        {/*<TableCell align="right">SKU</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allItemsArray.map((item) => (
                        <React.Fragment key={item._id + item.items._id}>
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell>
                                    <Checkbox
                                        // Checked if all nested orders are selected
                                        checked={
                                            selectedItems.some(i =>
                                                (i._id + i.items._id) === (item._id + item.items._id)
                                            )
                                        }
                                        onChange={(event) => handleTopLevelSelectAlone(event, item)}
                                    />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <img src={item.items.img} style={{width: "auto", height: '50px'}}/>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.items.name.replace("Zumos Frescos", "").replace("bot/plast.", "")}
                                </TableCell>



                                <TableCell align="right">
                                    <Link target="_blank" href={"/admin/orders/" + item._id}>
                                        {item.tradeName || item.userAcc.tradeName || item.userAcc.name}
                                    </Link>
                                </TableCell>
                                <TableCell align="right">{item.items.sku.includes("plast") ? "Plast" : "Crystal"}</TableCell>

                                <TableCell align="right">{item.items.quantity + " " + item.items.unit}</TableCell>
                                <TableCell align="right" sx={{color: "green", fontWeight: 'bold'}}>{statusBackground(item.items.status.name) || "#New"}</TableCell>

                                <TableCell align="right">
                                    {parseFloat(item.items.quantity * item.items.marketPriceCP).toFixed(2)  + "€"}
                                </TableCell>
                                <TableCell align="right">
                                    {parseFloat(item.items.quantity * item.items.ourPrice).toFixed(2)  + "€"}
                                </TableCell>
                                <TableCell align="right">

                                    {((findProfit(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity)) / findTotal(parseFloat(item.items.marketPriceCP), parseFloat(item.items.ourPrice), item.items.IVA, parseFloat(item.items.quantity))) * 100).toFixed(2) + "%"}
                                </TableCell>
                                <TableCell align="right">{item.deliveryDate + " " + item.deliveryTime}</TableCell>

                                {/*<TableCell align="right">{item.items.sku}</TableCell>*/}

                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default JuiceProductionList;