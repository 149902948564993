import React, { useEffect, useState } from 'react'
import './App.css'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import Header from './components/Header/Header'
import Orders from './components/Orders/Orders'
import Contacts from './components/Contacts/Contacts'
import ContactChange from './components/Contacts/ContactChange/ContactChange'
import OrdersChange from './components/Orders/OrdersChange/OrdersChange'
import Catalog from './components/Catalog/Catalog'
import CatalogChange from './components/Catalog/CatalogChange/CatalogChange'
import Autorization from './components/Autorization/Autorization'
import { ThemeProvider } from '@emotion/react'
import theme from './utils/themes/mainTheme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import History from './components/History/History'
import StaticPage from './components/StaticPage/StaticPage'
import OrdersHistory from './components/Orders/OrdersHistory/OrdersHistory'
import Profile from './components/Profile/Profile'
import ShipmentOrders from './components/Shipment/ShipmentOrders'
import JuiceProduction from './components/Juice/JuiceProduction'

import { useSelector } from 'react-redux'
import { StaticOrderFP } from './components/StaticOrder/StaticOrderFP/StaticOrderFP'
import WelcomePage from './components/WelcomePage/WelcomePage'
import OldAutorization from './components/Autorization/OldAutorization/OldAutorization'
import { ContactStats } from './components/Contacts/ContactStats/ContactStats'
import LogsPage from './components/LogsPage/LogsPage'
import { CatalogStats } from './components/CatalogStats/CatalogStats'

function App() {
  const loginObj = {
    VPF: 'aHreSEqUAgRa',
    TPO: 'URoSIBInGLIs',
  }
  const location = useLocation()
  const [name, setName] = useState(localStorage.getItem('name') || '')
  const [password, setPassword] = useState(localStorage.getItem('password') || '')
  const [isAutorization, setIsAutorization] = useState(localStorage.getItem('password') === loginObj[localStorage.getItem('name')] || false)

  //settings exp
  // const settings = useSelector((state) => state.userSettings.settings)

  // const [headerImage, setHeaderImage] = useState(settings.headerImage)
  // const [adminName, setAdminName] = useState(settings.adminName)

  // useEffect(() => {
  //   setHeaderImage(settings.headerImage)
  //   setAdminName(settings.adminName)
  // }, [settings])

  //WX+ZXyLEa5!3kBJf
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        {location.pathname.split('/')[2] !== 'static' &&
          location.pathname.split('/')[1] !== 'client' &&
          location.pathname.split('/')[1] !== 'auth' && <Header />}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ErrorBoundary>
            {/* {isAutorization && localStorage.getItem('password') === loginObj[localStorage.getItem('name')] ? ( */}
            {localStorage.getItem('acc') ? (
              <Routes>
                <Route
                  path='/'
                  element={<Navigate replace to='/admin/orders' />}
                  action={isAutorization && password === 'WX+ZXyLEa5!3kBJf' ? {} : { replace: true, to: '/auth' }}
                />
                <Route
                  path='/admin/orders'
                  element={<Orders />}
                  action={isAutorization && password === 'WX+ZXyLEa5!3kBJf' ? {} : { replace: true, to: '/auth' }}
                />
                <Route errorElement={<ErrorBoundary />} path='/welcome' element={<WelcomePage />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/contacts' element={<Contacts />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/contactsStats' element={<ContactStats />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/orders/:id' element={<OrdersChange />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/contacts/:id' element={<ContactChange />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/catalog' element={<Catalog />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/catalog/:id' element={<CatalogChange />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/history' element={<History />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/shipment' element={<ShipmentOrders />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/juice' element={<JuiceProduction/>} />
                <Route errorElement={<ErrorBoundary />} path='/client/static/:id' element={<StaticPage />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/ordersHistory' element={<OrdersHistory />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/ordersHistory/:id' element={<OrdersChange />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/profile' element={<Profile />} />
                <Route errorElement={<ErrorBoundary />} path='/client/static/order/:id' element={<StaticOrderFP />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/logs' element={<LogsPage />} />
                <Route errorElement={<ErrorBoundary />} path='/admin/catalogStats' element={<CatalogStats />} />
              </Routes>
            ) : (
              <Routes>
                <Route path='/' element={<Navigate replace to='/auth' />} />
                <Route
                  path='/auth'
                  element={
                    // <OldAutorization
                    //   loginObj={loginObj}
                    //   name={name}
                    //   setName={setName}
                    //   password={password}
                    //   setPassword={setPassword}
                    //   setIsAutorization={setIsAutorization}
                    // />
                    <Autorization />
                  }
                />

                <Route errorElement={<ErrorBoundary />} path='/client/static/*' element={<StaticPage />} />
                <Route path='*' element={<Navigate replace to='/auth' />} />
                <Route errorElement={<ErrorBoundary />} path='/client/static/order/:id' element={<StaticOrderFP />} />
              </Routes>
            )}
          </ErrorBoundary>
        </LocalizationProvider>
      </div>
    </ThemeProvider>
  )
}

export default App
