import { Box, Button, Link, Modal, TextField, useMediaQuery } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import { WhatsApp } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Alert } from '@mui/material'

export default function Carts({ contact }) {
  const [openCart, setOpenCart] = useState(false)
  const mobileView = useMediaQuery('(max-width: 798px)')

  const [updateContact] = mainApi.useUpdateContactMutation()
  const [createOrder] = mainApi.useCreateNewOrderMutation()
  const { data: buisnessContact, isLoading: isLoadingBuisnessContact } = mainApi.useGetBuisnessContactByTradeNameQuery(contact.tradeName)
  const dispatch = useDispatch()

  const formatDate = (date) => {
    const d = new Date(date)
    const day = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth() + 1).toString().padStart(2, '0') // Месяцы начинаются с 0
    const year = d.getFullYear()
    return `${day}.${month}.${year}`
  }

  const handleClearCart = async () => {
    const data = {
      id: contact._id,
      ...contact,
      currentCart: [],
    }
    delete data._id
    try {
      await updateContact({ data })
      dispatch(mainApi.util.invalidateTags(['Contact']))
    } catch (err) {
      console.log(err)
    }
  }

  const handleCreateOrder = async () => {
    const now = new Date()
    const deliveryDate = now.getUTCHours() < 7 ? formatDate(now) : formatDate(new Date(now.setDate(now.getDate() + 1)))
    const body = {
      data: {
        img: 'https://imgur.com/b6ByMHk.jpg',
        items: [...contact.currentCart],
        date: Date.now(),
        type: 'browser',
        deliveryTime: now.getUTCHours() < 7 ? 'PLM ☀️' : 'PLT 🌛',
        deliveryDate: deliveryDate,
        status: {},
        order: 'CP',
        paidValue: 0,
        userAcc: {
          ...contact,
        },
        tradeName: contact.value?.tradeName ? contact.value?.tradeName : '',
      },
    }
    try {
      await createOrder(body)
      alert('Order created successfully')
      handleClearCart()
    } catch (err) {
      console.log(err)
    }
  }

  const getLastTimeInProducts = () => {
    if (!contact || !contact.currentCart) {
      return null
    }

    let lastTimestamp = null
    contact.currentCart.forEach((item) => {
      if (item.timestamp && (!lastTimestamp || item.timestamp > lastTimestamp)) {
        lastTimestamp = item.timestamp
      }
    })
    return new Date(lastTimestamp).toLocaleString()
  }

  const [notification, setNotification] = useState('')
  const [showNotification, setShowNotification] = useState(false)

  const handleGoToWA = (contact) => {
    if (contact.phone) {
      window.open(`https://wa.me/${contact.phone.replace('+', '')}`, '_blank')
    } else {
      alert('Phone not found')
    }
  }

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false)
      }, 3000)
    }
  }, [showNotification])

  const copyOrderInFormat = () => {
    const formattedCart = contact.currentCart.map((item) => ({
      name: item.name,
      quantity: item.quantity,
      price: item.marketPriceCP,
    }))

    let copyText = '🛒 Productos:\n\n'

    formattedCart.forEach((item, index) => {
      copyText += `${index + 1}.${item.name} ${item.quantity}x${item.price}€ = ${(item.quantity * item.price).toFixed(2)}€ \n\n`
    })

    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setNotification('Copied to clipboard!')
        setShowNotification(true)
      })
      .catch((err) => {
        setNotification('Failed to copy!')
      })
  }

  return (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: contact.currentCart ? 'flex' : 'none',
        flexDirection: 'column',
        width: '100px',
        height: '100px',
        backgroundColor: 'red',
        margin: '10px auto',
      }}
    >
      <Button onClick={() => setOpenCart(true)} variant='contained'>
        Cart
      </Button>

      <Modal open={openCart} onClose={() => setOpenCart(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: mobileView ? '300px' : '750px',
            maxHeight: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',

            border: '1px solid black',
            borderRadius: '10px',
            padding: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              borderBottom: '1px solid black',
              marginBottom: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <WhatsApp sx={{ color: 'green', cursor: 'pointer', width: '50px', height: '50px' }} onClick={() => handleGoToWA(contact)} />
              <Box>
                <p
                  style={{
                    margin: '5px 0',
                    fontSize: '14px',
                  }}
                >
                  Name: {contact.name}
                  <Link sx={{ marginLeft: '10px' }} href={`/admin/contacts/${buisnessContact ? buisnessContact._id : contact._id}`}>
                    {contact.tradeName}
                  </Link>
                </p>
                <p
                  style={{
                    margin: '5px 0',
                    fontSize: '14px',
                  }}
                >
                  Date: {getLastTimeInProducts()}
                </p>
              </Box>
            </Box>
            <ContentCopyIcon sx={{ cursor: 'pointer' }} onClick={copyOrderInFormat} />
            {showNotification && (
              <Alert
                sx={{
                  position: 'absolute',
                  right: '10px',
                  top: '40px',
                  transition: 'opacity 0.3s ease-in-out',
                }}
                severity='info'
              >
                {notification}
              </Alert>
            )}
          </Box>
          {/* <Box sx={{ display: 'flex', borderBottom: '1px solid black', width: '100%', gap: '10px' }}>
            
            <p>Phone: {contact.phone}</p>
            <p>Tradename: {contact.tradeName}</p>
            
          </Box> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              width: '100%',
              '&::-webkit-scrollbar': { width: '8px' },
              '&::-webkit-scrollbar-thumb': { backgroundColor: '#5FC56E', borderRadius: '10px' },
              '&::-webkit-scrollbar-track': { backgroundColor: '#E7E7E7' },
              '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#5FC56E', opacity: '0.8' },
            }}
          >
            {contact.currentCart &&
              contact.currentCart.map((item) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: mobileView ? 'column' : 'row',
                    gap: '10px',
                    width: '100%',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                  key={item._id}
                >
                  <Box sx={{ display: 'flex', gap: '10px', width: '100%', alignItems: 'center', textAlign: 'center', flex: 4 }}>
                    <img src={item.img} alt={item.name} width={50} height={50} />
                    <p style={{ fontSize: '16px', flex: 3, margin: '5px 0' }}>{item.name}</p>
                  </Box>
                  <Box sx={{ display: 'flex', gap: '10px', width: '100%', alignItems: 'center', textAlign: 'center', flex: 2 }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        flex: 1,
                      }}
                    >
                      <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 0' }}>Quantity:</p>
                      <TextField sx={{ width: '100px' }} size='small' value={item.quantity} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        flex: 1,
                      }}
                    >
                      <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '5px 0' }}>Price:</p>
                      <TextField sx={{ width: '100px' }} size='small' value={item.marketPriceCP} />
                    </div>
                  </Box>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              paddingTop: '10px',
              marginTop: '10px',
              borderTop: '1px solid black',
            }}
          >
            <Button
              variant='contained'
              color='info'
              sx={{ width: '45%', backgroundColor: '#5FC56E', ':hover': { opacity: '0.8', backgroundColor: '#5FC56E' } }}
              onClick={handleClearCart}
            >
              Clear cart
            </Button>
            <Button
              variant='contained'
              color='success'
              sx={{ width: '45%', backgroundColor: '#92C55F', ':hover': { opacity: '0.8', backgroundColor: '#92C55F' } }}
              onClick={handleCreateOrder}
            >
              Confirm Order
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
