import React, { Fragment, useEffect, useMemo, useState } from 'react'
import './OrdersModal.scss'
import { Modal, Box, FormControl, TextField, CircularProgress, Button, Autocomplete, Switch, TextareaAutosize } from '@mui/material'
import { mainApi } from '../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import Sample from '../OrdersChange/Sample/Sample'

export default function OrdersModal({ open, setOpen, data }) {
  const { data: contactsData, isLoading: isLoadingContacts } = mainApi.useGetAllContactsQuery()
  const [createOrder, { isLoading, isSuccess }] = mainApi.useCreateNewOrderMutation()
  const dispatch = useDispatch()

  const [selectedContact, setSelectedContact] = useState({ value: null, label: '' })
  // const [selectedTradeName, setSelectedTradeName] = useState({ value: null, label: '' })
  const [contacts, setContacts] = useState([])

  const [time, setTime] = useState(false)

  console.log(data)
  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData)
      console.log(contactsData)
    }
  }, [contactsData])

  const showContacts = useMemo(() => {
    const uniqueContacts = Array.from(new Set(contacts.map((contact) => contact.name)))
    const uniqueOrders = Array.from(new Set(data.map((data) => data?.userAcc?.name)))
    const uniqueContactsNames = Array.from(new Set([...uniqueContacts, ...uniqueOrders]))
    console.log(uniqueContactsNames)

    return uniqueContactsNames
      .map((name, index) => {
        const contact = name && (contacts.find((c) => c.name === name) || data.find((c) => c?.userAcc?.name === name)?.userAcc)
        if (contact?.type) {
          return {
            label:
              contact.name +
              `(${contact.tradeName ? contact.tradeName : 'Not Set'})` +
              '-' +
              ((contact.type === 'user' && 'User') || (contact.type === 'userUnion' && 'Account') || 'Business'),
            value: contact,
            key: index,
          }
        }
        return null
      })
      .filter((contact) => contact !== null)
  }, [contacts])

  // const tradeNames = useMemo(() => {
  //   const uniqueTradeNames = Array.from(new Set(contacts.map((contact) => contact.tradeName)))

  //   return uniqueTradeNames.map((tradeName, index) => {
  //     return {
  //       label: tradeName,
  //       value: tradeName,
  //       key: index,
  //     }
  //   })
  // })

  useEffect(() => {
    if (isSuccess) {
      setOpen(false)
    }
  }, [isSuccess])

  const formatDate = (date) => {
    const d = new Date(date)
    const day = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth() + 1).toString().padStart(2, '0') // Месяцы начинаются с 0
    const year = d.getFullYear()
    const time = d.getUTCHours() >= 7 // Define time based on UTC hours
    return `${day}.${month}.${year}, ${time ? 'PLT 🌛' : 'PLM ☀️'}`
  }

  const createNewOrderInChangeOrder = async () => {
    if (selectedContact.value) {
      const now = new Date()
      const time = now.getUTCHours() >= 7
      const deliveryDate = now.getUTCHours() < 7 ? formatDate(now) : formatDate(new Date(now.setDate(now.getDate() + 1)))
      const body = {
        data: {
          img: 'https://imgur.com/b6ByMHk.jpg',
          items: [],
          date: Date.now(),
          type: 'browser',
          deliveryTime: time ? 'PLT 🌛' : 'PLM ☀️',
          deliveryDate: deliveryDate,
          status: {},
          order: 'CP',
          paidValue: 0,
          userAcc: {
            ...selectedContact.value,
            // tradeName: selectedTradeName.value ? selectedTradeName.value : '',
          },
          tradeName: selectedContact.value?.tradeName ? selectedContact.value?.tradeName : '',
        },
      }
      try {
        await createOrder(body)
        dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
      } catch (err) {
        console.error(err)
      }
    } else {
      alert('Please select a contact')
    }
  }

  useEffect(() => {
    console.log(data, 'selectedContact')
  }, [data])

  //text modal

  const [textModalOpen, setTextModalOpen] = useState(false)
  const [text, setText] = useState('')
  const [textOrder, setTextOrder] = useState('')

  const { data: textData, isLoading: isLoadingText } = mainApi.useGetOrderFromTextQuery(text, {
    skip: !textOrder && !text,
  })

  useEffect(() => {
    if (textData) {
      console.log(textData)
    }
  }, [textData])

  const getOrderText = async () => {
    setTextOrder(text)
    setTextModalOpen(true)
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box className='ordersModal__container'>
        <div className='ordersModal__container__item'>
          <p>Contact</p>
          {isLoadingContacts ? (
            <CircularProgress />
          ) : (
            <div>
              <FormControl sx={{ margin: '0 auto' }}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={selectedContact}
                  options={showContacts}
                  sx={{ width: 300, alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
                  renderOption={(props, option) => (
                    <Box sx={{ alignItems: 'center', justifyContent: 'center', textAlign: 'left' }} key={option.key} component='li' {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} label='Contact' />}
                  onChange={(e, value) => setSelectedContact(value)}
                />
              </FormControl>
              <div style={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center', margin: '0 20px' }}>
                <p style={{ margin: 0 }}>PLM ☀️</p>
                <Switch value={time} onChange={(e) => setTime(e.target.checked)} />
                <p style={{ margin: 0 }}>PLT 🌛</p>
              </div>
              {/* <FormControl sx={{ margin: '20px auto' }}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  value={selectedTradeName}
                  options={tradeNames}
                  sx={{ width: 300 }}
                  renderOption={(props, option) => (
                    <Box key={option.key} component='li' {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} label='Trade Name ????' />}
                  onChange={(e, value) => setSelectedTradeName(value)}
                />
              </FormControl> */}
            </div>
          )}
        </div>
        {isLoading ? (
          <CircularProgress sx={{ margin: '0 auto' }} />
        ) : (
          <Button variant='contained' onClick={createNewOrderInChangeOrder}>
            Create
          </Button>
        )}
        {isLoading ? (
          <CircularProgress sx={{ margin: '0 auto' }} />
        ) : (
          <Button variant='contained' onClick={() => setTextModalOpen(true)}>
            <Modal
              open={textModalOpen}
              onClose={(e) => {
                e.stopPropagation()
                setTextModalOpen(false)
              }}
            >
              <Box
                sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', flexDirection: 'column' }}
              >
                <TextareaAutosize
                  aria-label='empty textarea'
                  placeholder='Empty'
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  style={{ width: 300, height: 300, owerflow: 'auto', resize: 'none' }}
                />
                <Button variant='contained' onClick={() => getOrderText()}>
                  NEXT
                </Button>
              </Box>
            </Modal>
            Create from text
          </Button>
        )}
        {isLoading ? (
          <CircularProgress sx={{ margin: '0 auto' }} />
        ) : (
          <Sample deliveryTime={time ? 'PLT 🌛' : 'PLM ☀️'} order={{}} contact={selectedContact ? selectedContact.value : null} type='create' />
        )}

        {/* <Button variant='contained'>Create new User</Button> */}
      </Box>
    </Modal>
  )
}
