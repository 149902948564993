import { Box, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { mainApi } from '../../store/services/mainApi'

export const CatalogStats = () => {
  const [perPage, setPerPage] = useState(999999999999999)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [productCount, setProductCount] = useState([])

  useEffect(() => {
    const now = new Date()
    const sixMonthsAgo = new Date()
    sixMonthsAgo.setMonth(now.getMonth() - 6)
    setStartDate(sixMonthsAgo.getTime())
    setEndDate(now.getTime())
  }, [])

  // const { data: orders, isLoading: isLoadingOrders } = mainApi.useGetAllOrdersWithLimitQuery({
  //   limit: 1,
  //   startDate,
  //   endDate,
  // })

  const { data: ordersEmitido, isLoading: isLoadingOrdersEmitido } = mainApi.useGetAllOrdersEmitidoQuery({
    limit: perPage,
    // month: 6,
  })

  useEffect(() => {
    if (ordersEmitido) {
      const allOrders = [...ordersEmitido]
      console.log(allOrders)
      const productCountMap = {}

      const startMonth = new Date(allOrders[allOrders.length - 1].date)
      const lastMonth = new Date(allOrders[0].date)
      const totalMonths = (lastMonth.getFullYear() - startMonth.getFullYear()) * 12 + (lastMonth.getMonth() - startMonth.getMonth()) + 1

      allOrders.forEach((order) => {
        order.items.forEach((item) => {
          const { name, sku, quantity, img } = item
          if (!productCountMap[sku]) {
            productCountMap[sku] = { name, totalQuantity: quantity, img, sku }
          } else {
            productCountMap[sku].totalQuantity += quantity
          }
        })
      })

      const sortedProductCount = Object.values(productCountMap)
        .map((product) => ({
          ...product,
          averageQuantity: (product.totalQuantity / totalMonths).toFixed(0),
        }))
        .sort((a, b) => b.averageQuantity - a.averageQuantity)

      setProductCount(sortedProductCount)
    }
  }, [ordersEmitido])

  return (
    <Box>
      {isLoadingOrdersEmitido ? (
        <CircularProgress color='secondary' sx={{ position: 'absolute', top: '50%', left: '50%' }} />
      ) : (
        <Box>
          <h2 style={{ textAlign: 'center' }}>Cantidades mensuales vendidas (medianas)</h2>
          {productCount.map((product, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', margin: '10px 20px' }}>
              {/* <img style={{ width: '50px', height: '50px', flex: 1 }} src={product.img} alt={product.name} /> */}
              <p style={{ margin: '0', flex: 5 }}>{product.name}</p>
              <p style={{ margin: '0', flex: 1 }}>{product.averageQuantity}</p>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default CatalogStats
