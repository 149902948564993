import React, { useEffect, useMemo, useState } from 'react'
import '../../../../Table/TableBody/TableBodyItem/TableBodyItem.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import Boton from '../../../../../assets/png/boton.jpg'
import { ReactComponent as BotoBot } from '../../../../../assets/svg/BotoBot.svg'
import { ReactComponent as Whatsapp } from '../../../../../assets/svg/Whatsapp.svg'
import { ReactComponent as Telegram } from '../../../../../assets/svg/Telegram.svg'
import { ReactComponent as Browser } from '../../../../../assets/svg/Browser.svg'
import { ReactComponent as NF } from '../../../../../assets/svg/NF.svg'
import belarus from '../../../../../assets/png/Belarus.png'
import spainflag from '../../../../../assets/svg/Spainflag.svg'
import { Modal, Box, CircularProgress, TextField, Button, useMediaQuery, Link } from '@mui/material'
import ChangeStatus from '../../../../ChangeStatus/ChangeStatus'
export default function OrderTableBodyItem({ statuses, btntype, contacts, selectedItems, setSelectedItems, data, options, keys, isLoadingStatus }) {
  const location = useLocation()
  const navigate = useNavigate()

  const { type, status, date, items, _id, realType } = data
  const { name: statusName1, color: statusColor } = status

  const mobileScreen = useMediaQuery('(max-width: 768px)')

  //stautses

  const [statusModal, setStatusModal] = useState(false)

  const handleOpenModal = (event) => {
    setStatusModal(true)
  }

  //----------------------------

  //feature for colors and statuses

  //couter of visible options
  const visible = useMemo(() => {
    const count = Object.values(options).reduce((acc, item) => {
      if (item) {
        return acc + 1
      }
      return acc
    }, 0)
    return count
  }, [options])

  const MemomizedChangeStatus = React.memo(ChangeStatus)

  const currentDate = data?.deliveryDate
    ? typeof data?.deliveryDate == 'object'
      ? data?.deliveryDate[0]
        ? data?.deliveryDate[0]
        : new Date(date).toISOString().slice(0, 10).split('-').reverse().join('.')
      : data?.deliveryDate.split(',')[0]
    : new Date(date).toISOString().slice(0, 10).split('-').reverse().join('.')

  const deliverytimeIcon = useMemo(() => {
    if (data?.deliveryDate) {
      if (data.deliveryDate.includes('PLM')) {
        return '☀️'
      } else if (data.deliveryDate.includes('PLT')) {
        return '🌛'
      } else {
        if (data.deliveryDate.includes(':')) {
          // Измените условие здесь с 16 на 14
          return parseInt(data.deliveryDate.split(':')[0]) >= 14 ? '🌛' : '☀️'
        } else {
          return '☀️'
        }
      }
    }
    return ''
  }, [data?.deliveryDate])

  const currentClient = useMemo(() => {
    return contacts.filter((el) => {
      if (el.type == 'userUnion') {
        if (data.type == 'browser') {
          if (el.phone == data?.userAcc?.phone) {
            return el
          }
        }

        if (data.type == 'whatsapp') {
          if (el.phone == data?.msg?._data?.from.split('@')[0]) {
            return el
          }
        }

        if (data.type == 'telegram') {
          if (el.phone == data.userAcc?.phone || el.phone == data.userAcc?.findItem?.phone) {
            return el
          }
        }
      }
    })[0]
  }, [contacts])

  const handleSelect = () => {
    if (selectedItems.includes(data)) {
      setSelectedItems(selectedItems.filter((item) => item !== data))
    } else {
      setSelectedItems([...selectedItems, data])
    }
  }

  const phone = useMemo(() => {
    return data.type == 'browser'
      ? data.userAcc?.phone || data.userAcc?.findItem?.phone
      : data.type == 'telegram'
      ? +data.userAcc?.phone || data.userAcc?.findItem?.phone
      : +data.msg._data.from.split('@')[0]
  }, [data])

  const balance = useMemo(() => {
    return parseFloat(data.paidValue)
      ? (
          (parseFloat(data?.deliveryPrice) ? parseFloat(data.deliveryPrice) : 0) +
          data.items
            .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
            .reduce((acc, curr) => acc + curr, 0)
            .toFixed(2) -
          (parseFloat(data.paidValue).toFixed(2) || 0)
        ).toFixed(2)
      : (
          (parseFloat(data?.deliveryPrice) ? parseFloat(data.deliveryPrice) : 0) +
          data.items
            .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
            .reduce((acc, curr) => acc + curr, 0)
        ).toFixed(2)
  }, [data])

  // const margenCounterOrder = useMemo(() => {
  //   const margenArray = data.items.map((elem) => {
  //     const quantity = parseFloat(elem.quantity) || 1
  //     const marketPrice = parseFloat(elem.marketPriceCP)
  //     const ourPrice = parseFloat(elem.ourPrice)
  //     const iva = parseFloat(elem.IVA) || 0

  //     if (!isNaN(marketPrice) && !isNaN(ourPrice)) {
  //       const pr = marketPrice * quantity - ourPrice * quantity
  //       const niz = ourPrice * quantity + (ourPrice * quantity * iva) / 100

  //       return ((pr / niz) * 100).toFixed(2)
  //     } else {
  //       return '0.00'
  //     }
  //   })

  //   const parsedMargenArray = margenArray.map((elem) => parseFloat(elem)).filter((elem) => !isNaN(elem))

  //   const averageMargen =
  //     parsedMargenArray.length > 0 ? (parsedMargenArray.reduce((acc, num) => acc + num, 0) / parsedMargenArray.length).toFixed(2) : '0.00'

  //   return isNaN(averageMargen) ? '0.00' : averageMargen
  // }, [data])

  const calculateTotalPrice = (items, deliveryPrice) => {
    const totalItemsPrice = items
      .map((item) => (item.marketPriceCP !== '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
      .reduce((acc, curr) => acc + curr, 0)

    return data.deliveryPrice ? (parseFloat(deliveryPrice) + totalItemsPrice).toFixed(2) : totalItemsPrice.toFixed(2)
  }

  const totalPrice = useMemo(() => calculateTotalPrice(items, data.deliveryPrice), [data, items])

  const profitPercent = useMemo(() => {
    return (profit(items) / totalPrice) * 100
  }, [items, totalPrice])

  const readyPercent = useMemo(() => {
    const { sum, count } = items.reduce(
      (acc, item) => {
        if (item?.status?._id === '6584a9da0ca272b8a1c94df4') {
          acc.sum++
        }
        acc.count++
        return acc
      },
      { sum: 0, count: 0 },
    )

    return sum / count
  }, [items])

  return (
    <div
      className='tableBodyItem'
      style={{ backgroundColor: profitPercent < 30 ? '#fce8e8' : profitPercent > 70 ? '#fcea5b' : profitPercent >= 50 ? '#fcf3ac' : '#ecffed' }}
    >
      <input
        // style={{ width: '2%' }}
        type='checkbox'
        className='tableBody__title__checkbox'
        onChange={handleSelect}
        checked={selectedItems.includes(data)}
      />
      <div
        style={{
          // backgroundColor: `${mobileScreen ? (statusColor ? statusColor : '#fff') : 'transparent'}`,
          display: options[keys[0].toLowerCase()] ? 'block' : 'none',

          width: `${80 / visible}%`,
        }}
        className='tableBodyItem__item mobileid'
        // data-tooltip={data?.idTucomida ? '00' + data?.idTucomida : _id && _id.substring(0, 8)}
      >
        {realType == 'boton123' ? (
          <span>
            <img src={Boton} style={{ width: '20px', height: '20px' }} alt='' /> {_id && _id.substring(0, 6)}
          </span>
        ) : (
          <span
            style={{
              textAlign: 'start',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {realType == 'tucomida' ? (
              <BotoBot />
            ) : type == 'whatsapp' ? (
              <Whatsapp />
            ) : type == 'telegram' ? (
              <Telegram />
            ) : realType == 'boton' ? (
              <img src={Boton} style={{ width: '25px', height: '25px', borderRadius: '15px' }} alt='' />
            ) : type == 'browser' ? (
              <Browser />
            ) : (
              <NF />
            )}{' '}
            <Link
              className='tableBodyItem__item__link'
              style={{ color: '#1d2620', cursor: 'pointer' }}
              // to={`/admin/orders/${data._id}`}
              href={`/admin/orders/${data?._id}`}
              target='_blank'
              rel='noreferrer'
            >
              {data?.idTucomida ? data?.idTucomida : _id && _id.slice(-6)}
            </Link>
            <p
              style={{
                position: 'absolute',
                right: '-20px',
                bottom: '0px',
                minWidth: '20px',
                height: '20px',
                padding: '2px',
                borderRadius: '50%',
                backgroundColor: '#a4e996',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              {data.items ? data.items.length : 0}
            </p>
          </span>
        )}
        {/*orderNumber(date, data)*/}
      </div>
      {/* <div style={{ display: options[keys[1].toLowerCase()] ? 'block' : 'none' }} className='tableBodyItem__item platform' data-tooltip={type}>
        <span></span>
      </div> */}
      <div
        style={{ display: options[keys[1].toLowerCase()] ? 'block' : 'none', textAlign: 'left', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        data-tooltip={data?.tradeName || currentClient?.tradeName || data?.userAcc?.tradeName}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>User:</p>} */}
        <span style={{ textAlign: 'left', display: 'flex', justifyContent: 'space-between' }}>
          {data?.tradeName}
          <span style={{ color: readyPercent < 0.5 ? 'red' : readyPercent === 1 ? 'green' : 'orange' }}>{(readyPercent * 100).toFixed(0)}%</span>
        </span>
      </div>
      <div
        style={{ display: options[keys[2].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        data-tooltip={
          data.type == 'browser'
            ? data?.userAcc?.name || data?.userAcc?.email || 'User'
            : data.type == 'telegram'
            ? data.tg.user.first_name
            : data.msg?._data?.notifyName
        }
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Name:</p>} */}
        <span style={{ textAlign: 'left' }}>
          {data.type == 'browser'
            ? data?.userAcc?.name || data?.userAcc?.email || 'User'
            : data.type == 'telegram'
            ? data.tg.user.first_name
            : data.msg?._data?.notifyName}
        </span>
      </div>
      <div
        style={{
          display: options[keys[3].toLowerCase()] ? 'block' : 'none',
          position: 'relative',
          backgroundColor: data?.status?.color,
          width: `${80 / visible}%`,
        }}
        className={`tableBodyItem__item status mobileStatus `}
        onClick={handleOpenModal}
      >
        {isLoadingStatus ? (
          <CircularProgress color='inherit' size={20} />
        ) : (
          <MemomizedChangeStatus
            type='order'
            data={data}
            statusModal={statusModal}
            setStatusModal={setStatusModal}
            statuses={statuses}
            statusName1={data?.status?.name}
          />
        )}
      </div>
      <div
        style={{ display: options[keys[4].toLowerCase().replaceAll(' ', '')] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Delivery:</p>} */}
        {/* <span>{`${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear() % 100}`}</span> */}
        <span>
          {currentDate}
          {deliverytimeIcon}
        </span>
      </div>
      <div style={{ display: options[keys[5].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Total:</p>} */}

        <span
          style={{
            textAlign: 'right',
          }}
        >
          {totalPrice}€
        </span>
      </div>
      {/* {localStorage.getItem('name') != 'VPF' && ( */}
      <div style={{ display: options[keys[6].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Margen:</p>} */}
        <span
          style={{
            textAlign: 'right',
          }}
        >
          {profitPercent ? profitPercent.toFixed(2) + '%' : '0.00' + '%'}
        </span>
      </div>
      {/* )} */}
      {/* {localStorage.getItem('name') != 'VPF' && ( */}
      <div style={{ display: options[keys[7].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Profit:</p>} */}

        <span
          style={{
            textAlign: 'right',
          }}
        >
          {profit(items)}€
        </span>
      </div>
      {/* )} */}

      <div
        style={{
          display: options[keys[8].toLowerCase()] ? 'block' : 'none',
          fontWeight: 'bold',
          width: `${80 / visible}%`,
        }}
        className='tableBodyItem__item'
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Paid:</p>} */}
        <span
          style={{
            textAlign: 'right',
          }}
        >
          {(parseFloat(data.paidValue) ? parseFloat(data.paidValue).toFixed(2) : '0.00') + '€'}
        </span>
      </div>
      <div style={{ display: options[keys[9].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Balance:</p>} */}

        <span style={{ color: balance <= 0 ? 'green' : 'red', textAlign: 'right' }}>{balance}€</span>
      </div>
      <div
        style={{ display: options[keys[10].toLowerCase().replaceAll(' ', '')] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        data-tooltip={
          (parseInt(new Date(date).getHours()) < 10 ? '0' + new Date(date).getHours() : new Date(date).getHours()) +
          ':' +
          (parseInt(new Date(date).getMinutes()) < 10 ? '0' + new Date(date).getMinutes() : new Date(date).getMinutes())
        }
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Created:</p>} */}
        <span>{new Date(date).getDate() + '.' + (new Date(date).getMonth() + 1) + '.' + (new Date(date).getFullYear() % 100)}</span>
      </div>
      <div style={{ display: options[keys[11].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Country:</p>} */}
        <span>
          {(String(
            data.type == 'browser'
              ? data.userAcc?.phone || data.userAcc?.findItem?.phone
              : data.type == 'telegram'
              ? data.userAcc?.phone || data.userAcc?.findItem?.phone
              : data.msg._data.from.split('@')[0],
          ).startsWith('375') && (
            <img style={{ transform: 'rotate(0deg)', width: '25px', height: '20px', borderRadius: '15px' }} src={belarus} alt='' />
          )) ||
            (String(
              data.type == 'browser'
                ? data.userAcc?.phone || data.userAcc?.findItem?.phone
                : data.type == 'telegram'
                ? data.userAcc?.phone || data.userAcc?.findItem?.phone
                : data.msg._data.from.split('@')[0],
            ).startsWith('34') && <img src={spainflag} alt='' />)}
        </span>
      </div>
      <div
        style={{ display: options[keys[12].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        data-tooltip={phone ? '+' + phone.toString() : ''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Phone:</p>} */}
        <span>{phone ? '+' + phone.toString() : ''}</span>
      </div>
      <div
        style={{ display: options[keys[13].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        data-tooltip={data.description}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Comment:</p>} */}
        <span>{data.description}</span>
      </div>
      {btntype === 'ordersChange' ? (
        <div
          className='tableBodyItem__item more'
          onClick={() => {
            navigate(`/admin/orders/${data._id}`)
          }}
        >
          ...
        </div>
      ) : (
        <div
          className='tableBodyItem__item more'
          onClick={() => {
            navigate(`${location.pathname}/${data._id}`)
          }}
        >
          ...
        </div>
      )}
    </div>
  )
}

// export const totalPrice = (items) => {
//   const total = items.reduce((acc, item) => {
//     return acc + item.marketPrice
//   }, 0)

//   return Number(total).toFixed(2) + '€' // Returns the total price as a string with 2 decimal places
// }

// export const margenCounter = (items) => {
//   if (items.length > 0) {
//     let pr = 0
//     let niz = 0
//     items.forEach((item) => {
//       pr += parseFloat(item.marketPriceCP * (item.quantity || 1)) - parseFloat(item.ourPrice * (item.quantity || 1))
//       niz += parseFloat(item.ourPrice * (item.quantity || 1)) + (parseFloat(item.ourPrice * (item.quantity || 1)) * parseFloat(item.IVA)) / 100
//     })

//     if (niz === 0) {
//       return 0 + '%'
//     }

//     return ((pr / niz / items.length) * 100).toFixed(2) + '%'
//   } else {
//     return 0
//   }
// }

// export const profit = (items) => {
//   return items
//     .map((item) =>
//       items.marketPriceCP != ''
//         ? (parseFloat(item.marketPriceCP * (item.quantity || 1)) - parseFloat(item.ourPrice * (item.quantity || 1))) *
//           (1 - parseFloat(item.IVA || 0) / 100)
//         : 0,
//     )
//     .reduce((acc, curr) => acc + curr, 0)
//     .toFixed(2)
// }

export const profit = (items) => {
  const totalProfit = items
    .filter((item) => item.marketPriceCP !== '') // Exclude items without marketPriceCP
    .map((item) => {
      const quantity = parseFloat(item.quantity) || 1
      const marketPrice = parseFloat(item.marketPriceCP)
      const ourPrice = parseFloat(item.ourPrice)
      const iva = parseFloat(item.IVA) || 0

      if (!isNaN(marketPrice) && !isNaN(ourPrice)) {
        const profit = (marketPrice * quantity - ourPrice * quantity) * (1 - iva / 100)
        return isNaN(profit) ? 0 : profit
      } else {
        return 0
      }
    })
    .reduce((acc, curr) => acc + curr, 0)
    .toFixed(2)

  return isNaN(totalProfit) ? '0.00' : totalProfit
}

// export const orderNumber = (date, data) => {
//   const currentDate = new Date(date)
//   const browserType = {
//     browser: 'WW',
//     whatsapp: 'WA',
//     telegram: 'TG',
//   }
//   return `${
//     browserType[data?.type] +
//     (currentDate.getFullYear() % 100) +
//     (currentDate.getMonth() + 1 <= 9 ? '0' + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1) +
//     (currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate()) +
//     (data?.tradeName ? data?.tradeName : '') +
//     (data.type ? 'CP' : data.order) +
//     (data._id ? data._id.slice(-4) : '')
//   }`
// }
