import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  CircularProgress,
  Container,
  TextField,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { mainApi } from '../../store/services/mainApi'
import debounce from 'lodash.debounce'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function LogsPage() {
  useEffect(() => {
    document.title = 'LogsPage'
  }, [])

  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [limitPerPage, setLimitPerPage] = useState(20)

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((value) => {
      setDebouncedSearch(value)
    }, 500), // Adjust the delay as needed
    [],
  )

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearch(value)
    debounceSearch(value)
  }

  const { data: logsData, isLoading } = mainApi.useGetLogsQuery(
    {
      limit: limitPerPage,
      search: debouncedSearch,
    },
    // { pollingInterval: 5000 },
  )

  return (
    <Container>
      <Box>
        <h1>LogsPage</h1>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <TextField fullWidth label='Search' value={search} onChange={handleSearchChange} />
            {logsData &&
              logsData.map((log) => (
                <Box
                  sx={{
                    marginBottom: '10px',
                    padding: '10px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                  key={log.id}
                >
                  <p>{`${log.action} || ${log?.details?.user?.name} || ${new Date(log.timestamp)} || ${log?.details?.user?.phoneNumber}`}</p>
                  <InfoSection log={log} />
                  <LogSection log={log} />
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </Container>
  )
}

const LogSection = ({ log }) => {
  const [expanded, setExpanded] = useState(false)

  const handleToggle = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <Accordion expanded={expanded} onChange={handleToggle} sx={{ marginTop: '10px', backgroundColor: '#fafafa', color: 'black' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />} sx={{ justifyContent: 'space-between' }}>
        <Typography>{log?.details?.user?.name || 'No Name'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant='body2'>
          Username: {log?.details?.user?.email || 'N/A'}
          <br />
          Role: {log?.details?.user?.role || 'N/A'}
          <br />
          Site: {log?.details?.user?.site || 'N/A'}
          <br />
          Phone Number: {log?.details?.user?.phoneNumber || 'N/A'}
          <br />
          Email: {log?.details?.user?.email || 'N/A'}
          <br />
        </Typography>
      </AccordionDetails>
    </Accordion>
  )
}

const InfoSection = ({ log }) => {
  const orderId = log?.details?.orderId

  const { changes } = log?.details || {}

  return (
    <Box>
      <p>Order Id: {orderId}</p>
      {changes ? <OrderChanges changes={changes} /> : null}
      <p>{JSON.stringify(log?.details, null, 2) || 'N/A'}</p>
    </Box>
  )
}

const OrderChanges = ({ changes }) => {
  if (!changes || changes.modified.length === 0) {
    return <Typography>No changes available</Typography>
  }

  return (
    <Box sx={{ marginTop: '10px' }}>
      <Typography variant='h6'>Changes</Typography>
      {changes.modified.map((item) => {
        const { sku, changes: itemChanges } = item
        const quantityChange = itemChanges?.quantity || {}
        const statusChange = itemChanges?.status || {}
        const currientRequestChange = itemChanges?.currientRequest || {}
        const currentUnitChange = itemChanges?.currentUnit || {}
        const currentIVAChange = itemChanges?.IVA || {}
        const marketPriceCPChange = itemChanges?.marketPriceCP || {}

        return (
          <Accordion key={item.id} sx={{ marginTop: '5px', backgroundColor: '#f0f0f0' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${item.id}-content`} id={`${item.id}-header`}>
              <Typography sx={{ fontWeight: 'bold' }}>SKU: {sku}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <ListItem>
                  <ListItemText primary='Sold' secondary={`Old: ${quantityChange.old ?? 'N/A'}, New: ${quantityChange.new ?? 'N/A'}`} />
                  <ListItemText
                    sx={{ fontWeight: 'bold' }}
                    primary='Status'
                    secondary={`Old: ${statusChange.old?.name ?? 'N/A'}, New: ${statusChange.new?.name ?? 'N/A'}`}
                  />
                  <ListItemText
                    primary='Requested'
                    secondary={`Old: ${currientRequestChange.old ?? 'N/A'}, New: ${currientRequestChange.new ?? 'N/A'}`}
                  />
                  <ListItemText primary='Unit' secondary={`Old: ${currentUnitChange.old ?? 'N/A'}, New: ${currentUnitChange.new ?? 'N/A'}`} />
                  <ListItemText primary='IVA' secondary={`Old: ${currentIVAChange.old ?? 'N/A'}, New: ${currentIVAChange.new ?? 'N/A'}`} />
                  <ListItemText primary='Price' secondary={`Old: ${marketPriceCPChange.old ?? 'N/A'}, New: ${marketPriceCPChange.new ?? 'N/A'}`} />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}
