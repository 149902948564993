import React, { useState, useEffect } from 'react'
import './PersonalSales.scss'
import { Button, Modal, Box, useMediaQuery, TextField, CircularProgress, Checkbox } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export default function PersonalSales({ contact }) {
  const mobileView = useMediaQuery('(max-width: 798px)')
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const [addProductModal, setAddProductModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [selectedAddedProducts, setSelectedAddedProducts] = useState([])

  const { data: products, isLoading: isLoadingProducts } = mainApi.useGetProductsLimitQuery(
    {
      limit: 10,
      search: searchValue,
    },
    {
      skip: !addProductModal,
    },
  )

  const [addPersonalSales] = mainApi.useAddPersonalSaleMutation()

  const { tradeName } = contact

  const { data: personalSales, isLoading: isLoadingPersonal } = mainApi.useGetPersonalSalesQuery(tradeName, { skip: !open })

  useEffect(() => {
    if (personalSales) {
      setSelectedAddedProducts(personalSales)
    }
  }, [personalSales])

  const handleAddProducts = () => {
    addPersonalSales({
      tradeName: tradeName,
      personalSales: selectedAddedProducts,
    })
      .unwrap()
      .then(() => {
        setAddProductModal(false)
        alert('Products added')
        dispatch(mainApi.util.invalidateTags(['PersonalSales']))
      })
      .catch((err) => alert(err))
  }

  const handleDeleteAddedProduct = (sku) => {
    setSelectedAddedProducts((prev) => prev.filter((product) => product.sku !== sku))
  }

  const handleDelete = (sku) => {
    try {
      if (window.confirm('Are you sure you want to delete this product?')) {
        setSelectedAddedProducts((prev) => prev.filter((product) => product.sku !== sku))
      }
    } catch (err) {
      alert(err)
    }
  }
  const handleAddProduct = (product) => {
    setSelectedAddedProducts((prev) => [
      ...prev,
      { ...product, date: dayjs() }, // Add the current date to the product
    ])
  }

  const handlePriceChange = (event, sku) => {
    const newPrice = event.target.value
    setSelectedAddedProducts((prev) => prev.map((product) => (product.sku === sku ? { ...product, spoPrice: parseFloat(newPrice) } : product)))
  }

  const handleChangeSelected = (event, sku) => {
    setSelectedAddedProducts((prev) => prev.map((product) => (product.sku === sku ? { ...product, selected: event.target.checked } : product)))
  }

  const handleChangeDate = (newDate, sku) => {
    setSelectedAddedProducts((prev) => prev.map((product) => (product.sku === sku ? { ...product, date: newDate } : product)))
  }

  return (
    <div className='personalSales'>
      <h2>Personal Rates</h2>
      <Button onClick={() => setOpen(true)} variant='contained'>
        Open
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: mobileView ? '300px' : '800px',
            height: '80vh',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
          }}
        >
          <h2>Personal Rates</h2>
          <div
            style={{
              borderBottom: '1px solid #e7e7e7',
              display: 'inline-block',
              paddingBottom: '1px',
              marginBottom: '10px',
              width: '95%',
            }}
          ></div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '95%',
              overflow: 'auto',
              '&::-webkit-scrollbar': { width: '8px' },
              '&::-webkit-scrollbar-thumb': { backgroundColor: '#5FC56E', borderRadius: '10px' },
              '&::-webkit-scrollbar-track': { backgroundColor: '#E7E7E7' },
              '&::-webkit-scrollbar-thumb:hover': { backgroundColor: '#5FC56E', opacity: '0.8' },
            }}
          >
            {isLoadingPersonal ? (
              <CircularProgress />
            ) : (
              selectedAddedProducts.map((product) => (
                <Box
                  sx={{
                    fontFamily: 'Montserrat',
                    display: 'flex',
                    gap: '10px',
                    alignItems: mobileView ? 'none' : 'center',
                    margin: '0 20px',
                    flexDirection: mobileView ? 'column' : 'row',
                  }}
                  key={product._id}
                >
                  <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 3 }}>
                    <p
                      style={{
                        width: '15px',
                        height: '15px',
                        // fontWeight: 'bold',
                        fontSize: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'red',
                        color: 'white',
                        margin: 0,
                        padding: '2px',
                        border: '1px solid #e7e7e7',
                        borderRadius: '50%',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDelete(product.sku)}
                    >
                      -
                    </p>
                    <img width={50} height={50} src={product.img} alt={product.name} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', fontSize: '14px', flex: 4 }}>
                      <p style={{ flex: 2, fontSize: 14 }}>{product.name}</p>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p style={{ flex: 2, margin: 0 }}>{product.sku}</p>
                        <p style={{ flex: 1, fontSize: 20, fontWeight: 'bold', margin: 0 }}>{product.marketPriceCP}€</p>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: mobileView ? 'block' : 'flex', gap: '10px', alignItems: 'center', flex: 3 }}>
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 3 }}>
                      <div
                        style={{
                          flex: 1,
                          minWidth: '100px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <p style={{ margin: 0 }}>Selected</p>
                        <Checkbox checked={product.selected} onChange={(e) => handleChangeSelected(e, product.sku)} />
                      </div>

                      <TextField
                        type='number'
                        sx={{ flex: 1, minWidth: '80px' }}
                        value={product.spoPrice || ''}
                        onChange={(e) => handlePriceChange(e, product.sku)}
                        inputProps={{ step: '0.01' }}
                      />
                    </Box>
                    <Box>
                      <DatePicker
                        sx={{ flex: 2 }}
                        value={product.date ? dayjs(product.date) : null}
                        onChange={(e) => handleChangeDate(e, product.sku)}
                      />
                    </Box>
                  </Box>
                </Box>
              ))
            )}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', padding: '10px' }}>
            <Button
              sx={{ width: '45%', backgroundColor: '#5FC56E', ':hover': { opacity: '0.8', backgroundColor: '#5FC56E' } }}
              onClick={() => setAddProductModal(true)}
              variant='contained'
            >
              Add new
            </Button>
            {/* <Button variant='contained' color='info' onClick={handleSavePrices}>
              Save
            </Button> */}
            <Button
              sx={{ width: '45%', backgroundColor: '#92C55F', ':hover': { opacity: '0.8', backgroundColor: '#92C55F' } }}
              variant='contained'
              color='info'
              size='small'
              onClick={() => handleAddProducts()}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={addProductModal} onClose={() => setAddProductModal(false)}>
        {isLoadingProducts ? (
          <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />
        ) : (
          <Box
            sx={{
              width: mobileView ? '300px' : '600px',
              height: '300px',
              overflow: 'auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#f0f0f0',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                position: 'sticky',
                top: '0',
                backgroundColor: 'white',
                zIndex: '1000',
                padding: '10px',
              }}
            >
              <p>Search</p>
              <TextField variant='outlined' size='small' fullWidth onChange={(e) => setSearchValue(e.target.value)} />
            </Box>
            {products ? (
              products.map((product) => (
                <div
                  key={product._id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <img style={{ flex: '1' }} src={product.img} alt={product.name} width='50px' height='50px' />
                  <p style={{ flex: '3' }}>{product.name}</p>
                  {mobileView ? null : <p style={{ flex: 1 }}>{product.sku}</p>}
                  {selectedAddedProducts.some((p) => p.sku === product.sku) ? (
                    <Button sx={{ flex: '1' }} onClick={() => handleDeleteAddedProduct(product.sku)}>
                      Remove
                    </Button>
                  ) : (
                    <Button sx={{ flex: '1' }} onClick={() => handleAddProduct(product)}>
                      Add
                    </Button>
                  )}
                </div>
              ))
            ) : (
              <p>No products</p>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                position: 'sticky',
                bottom: '0',
                backgroundColor: 'white',
                zIndex: '1000',
                padding: '10px',
              }}
            >
              <Button fullWidth variant='contained' onClick={() => handleAddProducts()}>
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Modal>
    </div>
  )
}
