import React, { useEffect, useMemo, useState } from 'react'
import '../../../../Table/TableBody/TableModal/TableModal.scss'
import { ReactComponent as Copy } from '../../../../../assets/svg/Copy.svg'
import { ReactComponent as Export } from '../../../../../assets/svg/Export.svg'
import { ReactComponent as Status } from '../../../../../assets/svg/Status.svg'
import { ReactComponent as NewOrder } from '../../../../../assets/svg/NewOrder.svg'
import { ReactComponent as Truck } from '../../../../../assets/svg/Truck.svg'
import { ReactComponent as Delete } from '../../../../../assets/svg/Delete.svg'
import { mainApi } from '../../../../../store/services/mainApi'
import { Box, Button, CircularProgress, Modal, Select, MenuItem, InputLabel, FormControl, Link, TextField, Checkbox, Switch } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { statuses } from '../../../../../constants/Statuses'
import { printManyOrders } from '../../../../../utils/printManyOrders'
import ChangeStatus from '../../../../ChangeStatus/ChangeStatus'
import PDS from '../../../../PrintDocuments/Facturas/PDS'
import { usePDF } from '@react-pdf/renderer'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers'
import { logAction } from '../../../../../utils/logsAction/logService'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'

export default function OrderTableModal({
  setSelectedItems,
  selectedBalance,
  selectedPaid,
  selectedProfit,
  selectedTotal,
  selectedCogs,
  selectedItems,
}) {
  const [changeStatusofMany, { isLoading, isSuccess }] = mainApi.useUpdateStatusinManyOrdersMutation()
  const { data, isLoading: isLoadingStatus, isSuccess: isSuccessStatus } = mainApi.useGetAllStatusesQuery()
  const [deleteOrder, { isLoading: isLoadingDelete }] = mainApi.useDeleteOrderMutation()
  const [createOrder] = mainApi.useCreateNewOrderMutation()
  const [payOrders] = mainApi.usePayOrdersMutation()
  const { data: contactsData, isLoading: isLoadingContacts, isSuccess: isSuccessContacts } = mainApi.useGetAllContactsQuery()
  const dispatch = useDispatch()
  const settings = useSelector((state) => state.userSettings.settings)

  const [contacts, setContacts] = useState([])
  const [statuses, setStatuses] = useState([])

  const [statusModal, setStatusModal] = useState(false)

  const usr = JSON.parse(localStorage.getItem('acc'))

  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData)
    }
  }, [contactsData])

  //Addroute modal
  const [addRouteModal, setAddRouteModal] = useState(false)

  //dummy check

  const [dummyModal, setDummyModal] = useState(false)

  useEffect(() => {
    if (isSuccessStatus) {
      setStatuses(data.filter((status) => status.type === 'orders'))
    }
  }, [data, isSuccessStatus])

  async function updateStatus(status) {
    const body = {
      data: {
        allSelectedItems: selectedItems,
        crElem: status,
      },
    }
    logAction('Delete orders', {
      orderIds: selectedItems.map((item) => item._id),
      oldStatuses: selectedItems.map((item) => item.status),
      newStatus: status,
      user: {
        ...usr,
        actions: {},
      },
    })
    await changeStatusofMany(body)
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
    setSelectedItems([])
  }

  async function deleteOrders() {
    if (password !== '322') {
      alert('Wrong password')
    } else {
      Promise.all(
        selectedItems.map(async (item) => {
          await deleteOrder(item._id.toString()) // Pass the _id value as a string
        }),
      )
      logAction('Delete orders', {
        orderIds: selectedItems.map((item) => item._id),
        user: {
          ...usr,
          actions: {},
        },
      })
      dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
      setSelectedItems([])
    }
  }

  async function createNewOrder() {
    const body = {
      data: {
        items: [],
        date: Date.now(),
        type: 'browser',
        status: {},
        order: 'CP',
        paidValue: 0,
      },
    }
    await createOrder(body)
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
    setSelectedItems([])
  }

  async function payOrdersFunc() {
    Promise.all(
      selectedItems.map(async (item) => {
        let total = item.items
          .map((elem) => (elem.marketPriceCP ? parseFloat(elem.marketPriceCP * (elem.quantity || 1)) : 0))
          .reduce((acc, num) => acc + num, 0)

        await payOrders({
          data: {
            value: total,
            _id: item._id,
          },
        })
      }),
    )
    logAction('Pay orders', {
      orderIds: selectedItems.map((item) => item._id),
      user: {
        ...usr,
        actions: {},
      },
    })
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
    setSelectedItems([])
  }

  // async function exportOrders() {
  //   await fetch('http://localhost:2222/exportOrderAdmin', {
  //     method: 'POST',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(selectedItems),
  //   })
  // }

  async function copyOrders() {
    Promise.all(
      selectedItems.map(async (item) => {
        let body = {
          data: {
            ...item,
            _id: null,
            date: Date.now(),
          },
        }
        await createOrder(body)
      }),
    )
    logAction('Copy orders', {
      orderIds: selectedItems.map((item) => item._id),
      user: {
        ...usr,
        actions: {},
      },
    })
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
    setSelectedItems([])
  }

  //print modal

  const [printModal, setPrintModal] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      setSelectedItems([])
      setStatusModal(false)
    }
  }, [isSuccess])

  const [getLastNumInHistory, { isLoading: isLastNumInHistoryLoading }] = mainApi.useGetLastNumInHistoryMutation()

  const { data: uniqueCuentas } = mainApi.useGetUniqueCuentasQuery()
  const [numHistories, setNumHistories] = useState({})
  useEffect(() => {
    if (uniqueCuentas) {
      const fetchNumHistories = async () => {
        const results = {}
        for (const cuenta of uniqueCuentas) {
          try {
            const { data } = await getLastNumInHistory(cuenta)
            results[cuenta] = data.maxNum
          } catch (error) {
            console.error(`Failed to fetch number history for ${cuenta}:`, error)
          }
        }
        setNumHistories(results)
      }

      if (uniqueCuentas.length > 0) {
        fetchNumHistories()
      }
    }
  }, [uniqueCuentas])

  //FACTURA

  const [createHistory] = mainApi.useCreateNewHistoryValueMutation()

  const [currientContact, setCurrientContact] = useState({})

  const [selectedOrder, setSelectedOrder] = useState({})

  const [facturaDate, setFacturaDate] = useState(dayjs())

  const [facturaNumber, setFacturaNumber] = useState('001')
  const [facturaNumberInYear, setFacturaNumberInYear] = useState('001')

  const [dummyASF, setDummyASF] = useState(false)
  const [dummyACC, setDummyACC] = useState(false)
  const [dummyMSL, setDummyMSL] = useState(false)
  const [dummyACA, setDummyACA] = useState(false)

  const [dummyVPF, setDummyVPF] = useState(false)
  const [dummyVPFReal, setDummyVPFReal] = useState(false)

  useEffect(() => {
    if (selectedOrder && contacts) {
      contacts.find((contact) => {
        if (contact.tradeName === selectedOrder.tradeName && contact.type === 'client') {
          setCurrientContact(contact)
        }
        //  else {
        //   if (selectedOrder?.userAcc) {
        //     setCurrientContact({ ...selectedOrder.userAcc })
        //   } else {
        //     setCurrientContact({})
        //   }
        // }
      })
    } else {
      if (selectedOrder?.userAcc) {
        setCurrientContact({ ...selectedOrder.userAcc })
      } else {
        setCurrientContact({})
      }
    }
  }, [selectedOrder, contacts])

  // useEffect(() => {
  //   console.log(currientContact, 'currientContact')
  // }, [currientContact])

  const uniqueOrderBuisness = useMemo(() => {
    if (selectedItems) {
      const uniqueItems = selectedItems.filter((item, index, self) => index === self.findIndex((t) => t.tradeName === item.tradeName))
      console.log(uniqueItems, 'uniqueItems')
      return uniqueItems
    } else {
      return []
    }
  }, [selectedItems])

  //fourth Version
  const itemsForAlbaran = useMemo(() => {
    if (selectedItems) {
      const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

      selectedItems.forEach((order) => {
        if (order?.tradeName === currientContact?.tradeName) {
          order.items.forEach((item) => {
            const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

            if (!uniqueItemsMap[key]) {
              // Если элемент с таким ключом еще не существует, добавляем его в объект
              uniqueItemsMap[key] = {
                conceptio: item.name,
                sku: item.sku,
                quantity: item.quantity,
                price: item.marketPriceCP,
                IVA: item.IVA ? item.IVA : 0,
                tipo: item.currentUnit ? item.currentUnit : typeof item?.unit === 'string' ? item.unit : '',
                total: (item.marketPriceCP * item.quantity).toFixed(2),
              }
            } else {
              // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
              uniqueItemsMap[key].quantity += item.quantity
              uniqueItemsMap[key].total = (uniqueItemsMap[key].price * uniqueItemsMap[key].quantity).toFixed(2)
            }
          })
        }
      })

      // Преобразуем объект обратно в массив для возврата из useMemo
      const uniqueItems = Object.values(uniqueItemsMap)

      return uniqueItems
    } else {
      return []
    }
  }, [currientContact, selectedItems])

  //VPF FACTURA with handle iva and cogs
  const [handleIva, setHandleIva] = useState(0)

  const itemsForVPF = useMemo(() => {
    if (currientContact && currientContact?.tradeName === 'VPF') {
      if (selectedItems) {
        const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

        selectedItems.forEach((order) => {
          if (order?.tradeName === currientContact?.tradeName) {
            order.items.forEach((item) => {
              const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

              if (!uniqueItemsMap[key]) {
                // Если элемент с таким ключом еще не существует, добавляем его в объект
                uniqueItemsMap[key] = {
                  conceptio: item.name,
                  sku: item.sku,
                  quantity: item.quantity,
                  price: item?.ourPrice ? item.ourPrice : item?.marketPrice ? item.marketPrice : 0,
                  IVA: handleIva ? handleIva : 0,
                  tipo: item.currentUnit ? item.currentUnit : typeof item?.unit === 'string' ? item.unit : '',
                  total: ((item?.ourPrice ? item.ourPrice : item?.marketPrice ? item.marketPrice : 0) * item.quantity).toFixed(2),
                }
              } else {
                // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
                uniqueItemsMap[key].quantity += item.quantity
                uniqueItemsMap[key].total = (uniqueItemsMap[key].price * uniqueItemsMap[key].quantity).toFixed(2)
              }
            })
          }
        })

        // Преобразуем объект обратно в массив для возврата из useMemo
        const uniqueItems = Object.values(uniqueItemsMap)

        return uniqueItems
      } else {
        return []
      }
    } else {
      return []
    }
  }, [currientContact, selectedItems, handleIva])

  useEffect(() => {
    setSelectedOrder(uniqueOrderBuisness[0] || {})
  }, [uniqueOrderBuisness])

  const [instancePDS, updatePDS] = usePDF({
    document: (
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
        contact={currientContact}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />
    ),
  })

  const [instanceACC, updateACC] = usePDF({
    document: (
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        contact={currientContact}
        name={'Claudia Cires'}
        value={'Y4155866X'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
      />
    ),
  })
  const [instanceMSL, updateMSL] = usePDF({
    document: (
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
        contact={currientContact}
        name={'Moldovenesque SL'}
        value={'B67505446'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
      />
    ),
  })

  const [instanceVPF, updateVPF] = usePDF({
    document: (
      <PDS
        items={itemsForVPF}
        order={selectedOrder}
        // adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
        contact={currientContact}
        // name={'Moldovenesque SL'}
        // value={'B67505446'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        // IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
      />
    ),
  })

  const [instanceVPFReal, updateVPFReal] = usePDF({
    document: (
      <PDS
        items={[]}
        order={{}}
        contact={{}}
        adress={'Passeig Calvell 27, Barcelona, 08005'}
        name={'Roman Horokhov (VPF)'}
        value={'Y3467154X'}
        date={dayjs()}
        number={0}
        numberInYear={0}
        IBAN={'ES13 0081 5224 4100 0180 0081'}
      />
    ),
  })

  const handleDownloadVPFRealClick = () => {
    updateVPFReal(
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        contact={currientContact}
        adress={'Passeig Calvell 27, Barcelona, 08005'}
        name={'Roman Horokhov (VPF)'}
        value={'Y3467154X'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'ES13 0081 5224 4100 0180 0081'}
      />,
    )
    setPrintModal(false)
    setDummyVPFReal(true)
  }

  const handleDownloadASFClick = () => {
    updatePDS(
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        contact={currientContact}
        adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />,
    )
    setPrintModal(false)
    setDummyASF(true)
  }

  const handleDownloadACCClick = () => {
    updateACC(
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        contact={currientContact}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        name={'Claudia Cires'}
        value={'Y4155866X'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
      />,
    )
    setPrintModal(false)
    setDummyACC(true)
  }

  const handleDownloadMSLClick = () => {
    updateMSL(
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        contact={currientContact}
        adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
        name={'Moldovenesque SL'}
        value={'B67505446'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
      />,
    )
    setPrintModal(false)
    setDummyMSL(true)
  }

  const handleDownloadACAClick = () => {
    updateMSL(
      <PDS
        items={itemsForAlbaran}
        order={selectedOrder}
        contact={currientContact}
        adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
        name={'Alexandru Clim'}
        value={'Z0107137P'}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
        IBAN={'ES51 0081 0168 9100 0198 9510'}
      />,
    )
    setPrintModal(false)
    setDummyACA(true)
  }

  const handleVPFClick = () => {
    updateVPF(
      <PDS
        items={itemsForVPF}
        order={selectedOrder}
        contact={currientContact}
        date={facturaDate}
        number={facturaNumber}
        numberInYear={facturaNumberInYear}
      />,
    )
    setPrintModal(false)
    setDummyVPF(true)
  }

  const selectedItemsIds = useMemo(() => {
    if (selectedItems && currientContact) {
      return selectedItems.filter((item) => item?.tradeName === currientContact?.tradeName).map((item) => item._id)
    }
    return []
  }, [selectedItems, currientContact])

  const numForHistory = useMemo(() => {
    if (selectedItems && currientContact) {
      return (
        currientContact?.tradeName +
        facturaNumber.toString() +
        (facturaDate.month() + 1 < 10 ? '0' + (facturaDate.month() + 1) : facturaDate.month() + 1) +
        facturaDate.year().toString().substr(-2) +
        '/' +
        facturaNumberInYear
      )
    }
  }, [selectedItems, currientContact, facturaNumber, facturaNumberInYear, facturaDate])

  const totalForHistory = useMemo(() => {
    if (itemsForAlbaran) {
      return itemsForAlbaran.reduce((acc, curr) => acc + curr.price * (curr.quantity || 1), 0)
    }
  }, [itemsForAlbaran])

  const estadaForHistory = useMemo(() => {
    if (itemsForAlbaran) {
      return itemsForAlbaran.reduce((acc, curr) => acc + curr.price * (curr.quantity || 1), 0)
    }
  }, [itemsForAlbaran])

  const createNewHistory = async (cuenta) => {
    const body = {
      data: {
        fetcha: facturaDate,
        num: numForHistory,
        cliente: currientContact?.name + ` (${currientContact?.tradeName})`,
        cuenta,
        total: totalForHistory ? totalForHistory.toFixed(2) : '0.00',
        estada: estadaForHistory ? estadaForHistory.toFixed(2) : '0.00',
        orders: selectedItemsIds,
        items: [...itemsForAlbaran],
        contact: currientContact,
        order: selectedOrder,
        number: facturaNumber,
        numberInYear: facturaNumberInYear,
        status: {
          _id: '65c67104a608d972ab2bbac2',
          name: 'New Factura',
          desc: '',
          color: '#208dd5',
          openColorPicker: false,
          type: 'history',
        },
      },
    }
    await createHistory(body)
      .then(() => {
        alert('History created ')
      })
      .catch((err) => {
        alert('Error: ' + err)
      })
    logAction('Create history', {
      orderIds: selectedItems.map((item) => item._id),
      user: {
        ...usr,
        actions: {},
      },
    })
    dispatch(mainApi.util.invalidateTags(['History']))
  }

  //experemental
  // const updateTradeNameInOrderInfo = async () => {
  //   Promise.all(
  //     selectedItems.map(async (item) => {
  //       await fetch('https://tpomobi.shop/updateOrderTradeNameAdmin', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           data: {
  //             _id: item._id,
  //             tradeName: item?.userAcc?.tradeName ? item?.userAcc?.tradeName : '',
  //           },
  //         }),
  //       })
  //     }),
  //   )
  //   alert('Trade name successfully changed to in' + selectedItems.length + ' ' + 'items')
  // }

  // pass for delete
  const [password, setPassword] = useState('')

  useEffect(() => {
    console.log('selectedItems', selectedItems)
  }, [selectedItems])

  return (
    <div style={{ display: selectedItems.length > 0 ? 'flex' : 'none' }} className='tableModal'>
      <div style={{ display: 'flex', justifyContent: 'space-around' }} className='tableModal__title'>
        <span>Selected Rows</span>
        <span style={{ fontSize: '40px' }}>{selectedItems.length}</span>
      </div>
      <div className='vertical__line'></div>
      <div className='tableModal__body'>
        <div className='tableModal__body__item' onClick={() => copyOrders()}>
          <Copy width={30} height={30} />
          <p>Copy</p>
        </div>
        <a
          href={
            'https://tpomobi.shop/exportOrderAdmin?tradeName=' + localStorage.getItem('name') + '&_ids=' + selectedItems.map((el) => el._id).join(',')
          }
          rel='noopener noreferrer'
          target='_blank'
          className='tableModal__body__item'
          style={{ textDecoration: 'none', color: '#06c0b0' }}
        >
          <Export width={30} height={30} />
          <p>Export</p>
        </a>
        <div className='tableModal__body__item' onClick={() => setStatusModal(true)}>
          <Status width={30} height={30} />
          <p>Status</p>

          <Modal
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& .MuiBackdrop-root': {
                backgroundColor: 'transparent',
              },
            }}
            open={statusModal}
            onClose={() => setStatusModal(false)}
            onClick={(event) => event.stopPropagation()}
          >
            <Box
              sx={{
                width: 100,
                border: 'none',
                borderRadius: '10px',
                p: 4,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                  {statuses?.map((status) => (
                    <div
                      style={{
                        width: '100px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: status.color,
                      }}
                      className='tableModal__body__item status'
                      key={status._id}
                      onClick={() => {
                        updateStatus(status)
                        setStatusModal(false)
                      }}
                    >
                      {status.name}
                    </div>
                  ))}
                  <div
                    style={{
                      width: '100px',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'white',
                      border: '1px solid black',
                    }}
                    className='tableBodyItem__item status'
                    onClick={() => updateStatus({})}
                  >
                    <span>Delete</span>
                  </div>
                </Box>
              )}
            </Box>
          </Modal>
        </div>
        <Aggrupacion selectedItems={selectedItems} />
        <Unite selectedItems={selectedItems} setSelectedItems={setSelectedItems} />
        <div className='tableModal__body__item' onClick={() => setPrintModal(true)}>
          <Export width={30} height={30} />
          <p>Print</p>
          <Modal
            // sx={{
            //   '& .MuiBackdrop-root': {
            //     backgroundColor: 'transparent',
            //   },
            // }}
            open={printModal}
            onClose={(e) => {
              e.stopPropagation()
              setPrintModal(false)
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
                height: 400,
                backgroundColor: 'white',
                borderRadius: '10px',
                position: 'absolute',
                // bottom: '100px',
                // left: '35%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '10px',
              }}
            >
              <FormControl sx={{ m: 1, minWidth: 130 }}>
                <InputLabel>Buisness</InputLabel>
                <Select value={selectedOrder} onChange={(e) => setSelectedOrder(e.target.value)} sx={{ width: 250 }} label='Buisness'>
                  {uniqueOrderBuisness.map((item) => (
                    <MenuItem key={item._id} value={item}>
                      {item?.tradeName || item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <DatePicker label='Date' value={facturaDate} onChange={(newValue) => setFacturaDate(newValue)} />
              </FormControl>
              <TextField sx={{ width: 250 }} label='Number this year' value={facturaNumber} onChange={(e) => setFacturaNumber(e.target.value)} />
              <TextField
                sx={{ width: 250 }}
                label='Number in year'
                value={facturaNumberInYear}
                onChange={(e) => setFacturaNumberInYear(e.target.value)}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px' }}>
                {settings.adminName === 'TPO' && (
                  <Button
                    onClick={handleDownloadASFClick}
                    variant='outlined'
                    sx={{
                      color: '#407BFF',
                      fontWeight: 'bold',
                      fontFamily: 'Archivo Black',
                      textDecoration: 'none',
                      underline: 'none',
                      cursor: 'pointer',
                      padding: '5px',
                      width: 'max-content',
                      margin: '0',
                    }}
                  >
                    ASF ({numHistories['Sergey Fedorenko'] || 0})
                  </Button>
                )}
                {settings.adminName === 'TPO' && (
                  <Button
                    onClick={handleDownloadACCClick}
                    sx={{
                      // padding: '0px',
                      color: '#407BFF',
                      fontWeight: 'bold',
                      fontFamily: 'Archivo Black',
                      textDecoration: 'none',
                      underline: 'none',
                      cursor: 'pointer',
                      padding: '5px',
                      width: 'max-content',
                    }}
                    variant='outlined'
                  >
                    ACC ({numHistories['Claudia Cires'] || 0})
                  </Button>
                )}
                {settings.adminName === 'TPO' && (
                  <Button
                    onClick={handleDownloadMSLClick}
                    sx={{
                      color: '#407BFF',
                      fontWeight: 'bold',
                      fontFamily: 'Archivo Black',
                      textDecoration: 'none',
                      underline: 'none',
                      cursor: 'pointer',
                      padding: '5px',
                      width: 'max-content',
                    }}
                    variant='outlined'
                  >
                    MSL ({numHistories['Moldovenesque SL'] || 0})
                  </Button>
                )}
                {settings.adminName === 'TPO' && (
                  <Button
                    onClick={handleDownloadACAClick}
                    sx={{
                      color: '#407BFF',
                      fontWeight: 'bold',
                      fontFamily: 'Archivo Black',
                      textDecoration: 'none',
                      underline: 'none',
                      cursor: 'pointer',
                      padding: '5px',
                      width: 'max-content',
                    }}
                    variant='outlined'
                  >
                    ACA ({numHistories['Alexandru Clim'] || 0})
                  </Button>
                )}
                {settings.adminName === 'VPF' && (
                  <Button
                    onClick={handleDownloadVPFRealClick}
                    sx={{
                      color: '#407BFF',
                      fontWeight: 'bold',
                      fontFamily: 'Archivo Black',
                      textDecoration: 'none',
                      underline: 'none',
                      cursor: 'pointer',
                      padding: '5px',
                      width: 'max-content',
                    }}
                    variant='outlined'
                  >
                    VPF ({numHistories['Roman Horokhov'] || 0})
                  </Button>
                )}
                {selectedOrder?.tradeName === 'VPF' && (
                  <Button onClick={() => setDummyVPF(true)} sx={{ color: '#407BFF', width: 80 }} variant='outlined'>
                    VPF ({numHistories['Roman Horokhov'] || 0})
                  </Button>
                )}
                {selectedOrder?.tradeName === 'VPF' && (
                  <Select sx={{ width: 80 }} label='IVA' value={handleIva} onChange={(e) => setHandleIva(e.target.value)}>
                    <MenuItem value={0}>0%</MenuItem>
                    <MenuItem value={4}>4%</MenuItem>
                    <MenuItem value={5}>5%</MenuItem>
                    <MenuItem value={10}>10%</MenuItem>
                    <MenuItem value={21}>21%</MenuItem>
                  </Select>
                )}
              </Box>
            </Box>
          </Modal>
          <Modal open={dummyASF} onClose={(e) => setDummyASF(false)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
                height: 400,
                backgroundColor: 'white',
                borderRadius: '10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {instancePDS.loading ? (
                <Box>
                  <h6>Loading ...</h6>
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instancePDS.url}
                      download={`FACTURA-ASF-${selectedOrder?.tradeName}.pdf` || `FACTURA-ASF-${selectedOrder?.name}.pdf`}
                      // target='_blank'
                      // rel='noreferrer'
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                      onClick={() => createNewHistory('Sergey Fedorenko')}
                    >
                      Download ASF
                    </Link>
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instancePDS.url}
                      // download={`FACTURA-ASF-${selectedOrder?.tradeName}.pdf` || `FACTURA-ASF-${selectedOrder?.name}.pdf`}
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                    >
                      Open ASF
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal open={dummyACC} onClose={(e) => setDummyACC(false)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
                height: 400,
                backgroundColor: 'white',
                borderRadius: '10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {instancePDS.loading ? (
                <Box>
                  <h6>Loading ...</h6>
                  <CircularProgress />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceACC.url}
                      download={`FACTURA-ACC-${selectedOrder?.tradeName}.pdf` || `FACTURA-ACC-${selectedOrder?.name}.pdf`}
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                      onClick={() => createNewHistory('Claudia Cires')}
                    >
                      Download ACC
                    </Link>
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceACC.url}
                      target='_blank'
                      rel='noreferrer'
                      // download={`FACTURA-ACC-${selectedOrder?.tradeName}.pdf` || `FACTURA-ACC-${selectedOrder?.name}.pdf`}
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                    >
                      Open ACC
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal open={dummyMSL} onClose={(e) => setDummyMSL(false)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
                height: 400,
                backgroundColor: 'white',
                borderRadius: '10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {instancePDS.loading ? (
                <Box>
                  <h6>Loading ...</h6>
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceMSL.url}
                      download={`FACTURA-MSL-${selectedOrder?.tradeName}.pdf` || `FACTURA-MSL-${selectedOrder?.name}.pdf`}
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                      onClick={() => createNewHistory('Moldovenesque SL')}
                    >
                      Download MSL
                    </Link>
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceMSL.url}
                      // download={`FACTURA-MSL-${selectedOrder?.tradeName}.pdf` || `FACTURA-MSL-${selectedOrder?.name}.pdf`}
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                    >
                      Open MSL
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal open={dummyACA} onClose={(e) => setDummyACA(false)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
                height: 400,
                backgroundColor: 'white',
                borderRadius: '10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {instancePDS.loading ? (
                <Box>
                  <h6>Loading ...</h6>
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceMSL.url}
                      download={`FACTURA-ACA-${selectedOrder?.tradeName}.pdf` || `FACTURA-ACA-${selectedOrder?.name}.pdf`}
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                      onClick={() => createNewHistory('Alexandru Clim')}
                    >
                      Download ACA
                    </Link>
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceMSL.url}
                      // download={`FACTURA-MSL-${selectedOrder?.tradeName}.pdf` || `FACTURA-MSL-${selectedOrder?.name}.pdf`}
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                    >
                      Open ACA
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal open={dummyVPF} onClose={(e) => setDummyVPF(false)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
                height: 400,
                backgroundColor: 'white',
                borderRadius: '10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {instancePDS.loading ? (
                <Box>
                  <h6>Loading ...</h6>
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceVPF.url}
                      download={`SPECIALVPF-${selectedOrder?.tradeName}.pdf` || `FACTURA-MSL-${selectedOrder?.name}.pdf`}
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                      // onClick={() => createNewHistory('Roman Horokhov (VPF)')}
                    >
                      Download Special VPF
                    </Link>
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceVPF.url}
                      // download={`FACTURA-MSL-${selectedOrder?.tradeName}.pdf` || `FACTURA-MSL-${selectedOrder?.name}.pdf`}
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                    >
                      Open Special VPF
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
          <Modal open={dummyVPFReal} onClose={(e) => setDummyVPFReal(false)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                width: 300,
                height: 400,
                backgroundColor: 'white',
                borderRadius: '10px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {instancePDS.loading ? (
                <Box>
                  <h6>Loading ...</h6>
                  <CircularProgress />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceVPFReal.url}
                      download={`SPECIALVPF-${selectedOrder?.tradeName}.pdf` || `FACTURA-VPF-${selectedOrder?.name}.pdf`}
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                      onClick={() => createNewHistory('Roman Horokhov')}
                    >
                      Download VPF
                    </Link>
                  </Button>
                  <Button
                    sx={{
                      padding: '0px',
                    }}
                    variant='outlined'
                  >
                    <Link
                      href={instanceVPFReal.url}
                      // download={`FACTURA-MSL-${selectedOrder?.tradeName}.pdf` || `FACTURA-MSL-${selectedOrder?.name}.pdf`}
                      target='_blank'
                      rel='noreferrer'
                      style={{
                        color: '#407BFF',
                        fontWeight: 'bold',
                        fontFamily: 'Archivo Black',
                        textDecoration: 'none',
                        underline: 'none',
                        cursor: 'pointer',
                        padding: '5px',
                        width: '100%',
                      }}
                    >
                      Open VPF
                    </Link>
                  </Button>
                </Box>
              )}
            </Box>
          </Modal>
        </div>
        <div className='tableModal__body__item' onClick={() => payOrdersFunc()}>
          <Copy width={30} height={30} />
          <p>Payment</p>
        </div>
        <div onClick={() => setAddRouteModal(true)} className='tableModal__body__item'>
          <Truck width={30} height={30} />
          <p>Add Route</p>
          <AddRouteModal
            addRouteModal={addRouteModal}
            setAddRouteModal={setAddRouteModal}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </div>
        <div className='tableModal__body__item' onClick={() => setDummyModal(true)}>
          <Delete width={30} height={30} />
          <p className='red'>Delete</p>
          <Modal open={dummyModal} onClose={() => setDummyModal(false)}>
            <Box
              sx={{
                width: 200,
                border: 'none',
                borderRadius: '10px',
                p: 4,
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <p>Are you sure? Delete {selectedItems.length} orders ?</p>

              <img width={200} height={200} alt='gif' src='https://media.giphy.com/media/lKKXOCVviOAXS/giphy-downsized-large.gif' />
              <TextField sx={{ m: 2 }} label='Password' type='password' onChange={(e) => setPassword(e.target.value)} value={password} />
              {isLoadingDelete ? (
                <CircularProgress sx={{ mt: 2 }} />
              ) : (
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Button
                    color='error'
                    variant='contained'
                    onClick={(e) => {
                      e.stopPropagation()
                      deleteOrders()
                      setDummyModal(false)
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    color='success'
                    variant='contained'
                    onClick={(e) => {
                      e.stopPropagation()
                      setDummyModal(false)
                    }}
                  >
                    No
                  </Button>
                </div>
              )}
            </Box>
          </Modal>
        </div>
        <div className='tableModal__body__item'>
          <p className='black'>Total Sold</p>
          <p className='green'>{selectedTotal}</p>
        </div>
        {localStorage.getItem('name') != 'VPF' && (
          <div className='tableModal__body__item'>
            <p className='black'>COGS</p>
            <p className='green'>{selectedCogs}</p>
          </div>
        )}
        {localStorage.getItem('name') != 'VPF' && (
          <div className='tableModal__body__item'>
            <p className='black'>Estimated Profit</p>
            <p className='green'>{selectedProfit}</p>
          </div>
        )}
        <div className='tableModal__body__item'>
          <p className='black'>Paid</p>
          <p className='green'>{selectedPaid}</p>
        </div>
        <div className='tableModal__body__item'>
          <p className='black'>Balance</p>
          <p className='green'>{selectedBalance}</p>
        </div>
        {/* <div
          onClick={() => {
            updateTradeNameInOrderInfo()
          }}
          className='tableModal__body__item'
        >
          <p>Change Tradenames</p>
        </div> */}
        <div className='tableModal__body__btn ' onClick={() => setSelectedItems([])}>
          x
        </div>
      </div>
    </div>
  )
}

function GoogleMapsLink({ tradeName }) {
  const { data, isLoading } = mainApi.useGetAdressByTradeNameQuery(tradeName)

  const [address, setAddress] = useState('')

  useEffect(() => {
    if (data) {
      setAddress(data)
    }
  }, [data])

  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`

  return (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      google
    </a>
  )
}

export function AddRouteModal({ addRouteModal, setAddRouteModal, selectedItems, setSelectedItems }) {
  const initialRouteOrder = selectedItems.map((_, index) => index + 1)
  const [routeOrder, setRouteOrder] = useState(initialRouteOrder)
  const [addresses, setAddresses] = useState({})

  const tradeNames = selectedItems.map((item) => item.tradeName)

  const { data, isLoading } = mainApi.useGetAdressesByTradeNamesQuery(tradeNames, {
    skip: !tradeNames.length && !addRouteModal,
  })

  useEffect(() => {
    if (data) {
      const addressMap = selectedItems.reduce((acc, item) => {
        const addressEntry = data.find((entry) => entry[item.tradeName])
        if (addressEntry) {
          acc[item.tradeName] = addressEntry[item.tradeName]
        }
        return acc
      }, {})
      setAddresses(addressMap)
    }
  }, [data, selectedItems])

  const handleOrderChange = (index, value) => {
    const newOrder = [...routeOrder]
    newOrder[index] = parseInt(value, 10)
    setRouteOrder(newOrder)
  }

  const sortedItems = selectedItems.map((item, index) => ({ ...item, order: routeOrder[index] })).sort((a, b) => a.order - b.order)

  const googleMapsRouteUrl = `https://www.google.com/maps/dir/${encodeURIComponent('IV Gama Loor')}/${sortedItems
    .map((item) => encodeURIComponent(addresses[item.tradeName] || ''))
    .join('/')}`

  return (
    <Modal
      open={addRouteModal}
      onClose={(e) => {
        e.stopPropagation()
        setAddRouteModal(false)
      }}
    >
      <Box
        sx={{
          width: '80vw',
          maxHeight: '80vh',
          overflow: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: 'none',
          borderRadius: '10px',
          p: 4,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {selectedItems ? (
          selectedItems.map((el, index) => (
            <Box key={el._id} sx={{ display: 'flex', gap: '10px', alignItems: 'center', width: '100%' }}>
              <TextField
                size='small'
                sx={{ flex: 1 }}
                type='number'
                value={routeOrder[index]}
                onChange={(e) => handleOrderChange(index, e.target.value)}
              />
              <p style={{ flex: 1 }}>{el.tradeName}</p>
              <p style={{ flex: 3 }}>{addresses[el.tradeName] ? addresses[el.tradeName] : 'No address'}</p>
              <Box
                sx={{
                  flex: 2,
                }}
              >
                <p>Payment</p>
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <p>Card</p>
                  <Switch defaultChecked />
                  <p>Cash</p>
                </Box>
              </Box>
              <GoogleMapsLink style={{ flex: 3 }} address={addresses[el.tradeName] || 'Loading...'} />
            </Box>
          ))
        ) : (
          <p>Select Orders</p>
        )}
        <Button sx={{ padding: '0px' }} variant='outlined' fullWidth href={googleMapsRouteUrl} target='_blank' rel='noopener noreferrer'>
          Build Route
        </Button>
        <Button>
          <WADriversPhones selectedItems={sortedItems} setSelectedItems={setSelectedItems} link={googleMapsRouteUrl} />
        </Button>
      </Box>
    </Modal>
  )
}

const Unite = ({ selectedItems, setSelectedItems }) => {
  const allTradeNamesSame = selectedItems.every((item, _, arr) => item.tradeName === arr[0].tradeName)
  const [createOrder] = mainApi.useCreateNewOrderMutation()
  const [deleteOrder] = mainApi.useDeleteOrderMutation()
  const dispatch = useDispatch()
  const usr = JSON.parse(localStorage.getItem('acc'))
  const createNewOrderAndDeleteOld = async () => {
    if (!selectedItems.length) return null

    const [firstItem] = selectedItems

    const newOrder = {
      data: {
        date: Date.now(),
        type: 'browser',
        order: 'CP',
        paidValue: selectedItems.reduce((total, item) => total + (item?.paidValue || 0), 0),
        deliveryDate: firstItem.deliveryDate,
        deliveryTime: firstItem.deliveryTime,
        status: {},
        realType: firstItem.realType ? firstItem.realType : '',
        userAcc: firstItem.userAcc,
        items: selectedItems.flatMap((item) => item.items.map((subItem) => ({ ...subItem, _id: subItem._id || null }))),
        tradeName: firstItem?.tradeName,
      },
    }

    await createOrder(newOrder).then(() => {
      Promise.all(
        selectedItems.map((item) => {
          return deleteOrder(item._id)
        }),
      )
      logAction('Unite orders', {
        orderIds: selectedItems.map((item) => item._id),
        user: {
          ...usr,
          actions: {},
        },
      })
      dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
      setSelectedItems([])
    })
  }

  return (
    allTradeNamesSame && (
      <div
        onClick={() => {
          confirm('Are you sure you want to unite these orders?') && createNewOrderAndDeleteOld()
        }}
        className='tableModal__body__item'
      >
        {selectedItems.map((item, index) => (
          <div key={index}>{item.TradeName}</div>
        ))}
        <NewOrder width={30} height={30} />
        <p>Unite</p>
      </div>
    )
  )
}

const Aggrupacion = ({ selectedItems }) => {
  const [aggmodal, setAggmodal] = useState(false)

  const products = useMemo(() => {
    const productMap = {}

    selectedItems.forEach((item) => {
      item.items.forEach((elem) => {
        const key = `${elem.sku}-${elem.marketPriceCP}`
        if (productMap[key]) {
          productMap[key].quantity += elem.quantity
        } else {
          productMap[key] = { ...elem, order: item.order }
        }
      })
    })

    // Convert the productMap to an array and sort by quantity
    return Object.values(productMap).sort((a, b) => b.quantity - a.quantity)
  }, [selectedItems])

  return (
    <div className='tableModal__body__item' onClick={() => setAggmodal(true)}>
      <NewOrder width={30} height={30} />
      <p>Grouping</p>

      <Modal
        open={aggmodal}
        onClose={(e) => {
          e.stopPropagation()
          setAggmodal(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            borderRadius: '10px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '70vh',
            maxWidth: 300,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              p: 2,
              borderRadius: '10px 10px 0 0',
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
              width: '100%',
              backgroundColor: '#5FC56E',
            }}
          >
            <p style={{ flex: 2, padding: '0 10px' }}>Product</p>
            <p style={{ flex: 1, padding: '0 10px' }}>Market Price</p>
            <p style={{ flex: 1, padding: '0 10px' }}>Quantity</p>
          </Box>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              alignItems: 'center',
              width: '100%',
              backgroundColor: 'white',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {products.map((item, index) => (
              <Box key={index} sx={{ display: 'flex', alignItems: 'center', width: '100%', padding: '0 10px' }}>
                <Box sx={{ flex: 2, wordWrap: 'break-word', overflowWrap: 'break-word', wordBreak: 'break-word', padding: '0 10px' }}>
                  <p style={{ margin: 0 }}>{item.name}</p>
                  <p style={{ margin: 0, color: 'gray', fontSize: '14px' }}>{item.sku}</p>
                </Box>
                <p style={{ flex: 1, padding: '0 10px' }}>{item.marketPriceCP.toFixed(2)}</p>
                <p style={{ flex: 1, padding: '0 10px' }}>{item.quantity.toFixed(2)}</p>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              width: '100%',
              p: 2,
              borderRadius: '0 0 10px 10px',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{
                p: 2,
                width: '50%',
                backgroundColor: '#5FC56E',
                color: 'white',
                margin: '0 auto',
              }}
              onClick={() => setAggmodal(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

const WADriversPhones = ({ selectedItems, setSelectedItems, link }) => {
  const [sendOrdersToDriverTemplate] = mainApi.useSendOrdersToDriverTemplateMutation()
  const [createDriver] = mainApi.useDriverListCreateMutation()
  const [deleteDriver] = mainApi.useDriverListDeleteMutation()
  const [updateDriver] = mainApi.useDriverListUpdateMutation()

  const [changeModal, setChangeModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState({})
  const [selectedContactName, setSelectedContactName] = useState('')
  const [selectedContactPhone, setSelectedContactPhone] = useState('')

  useEffect(() => {
    if (selectedContact) {
      console.log(selectedContact)

      setSelectedContactName(selectedContact.name)
      setSelectedContactPhone(selectedContact.phone)
    }
  }, [selectedContact])

  const handleDeleteDriver = async () => {
    if (confirm('Are you sure you want to delete this driver?')) {
      await deleteDriver(selectedContact._id).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
      setSelectedContact({})
    }
  }

  const handleCreateDriver = async () => {
    if (confirm('Are you sure you want to create this driver?')) {
      await createDriver({
        name: createName,
        phone: createPhone,
      })
        .then(() => {
          dispatch(mainApi.util.invalidateTags(['Drivers']))
          setCreateModal(false)
        })
        .catch((err) => {
          alert(err)
        })
    }
  }

  const handleUpdateDriver = async () => {
    if (confirm('Are you sure you want to update this driver?')) {
      await updateDriver({
        _id: selectedContact._id,
        name: selectedContactName,
        phone: selectedContactPhone,
      }).then(() => {
        dispatch(mainApi.util.invalidateTags(['Drivers']))
        setChangeModal(false)
      })
    }
  }

  const { data: drivers } = mainApi.useGetDriversQuery()

  const dispatch = useDispatch()
  //send confirm

  const [createModal, setCreateModal] = useState(false)
  const [createName, setCreateName] = useState('')
  const [createPhone, setCreatePhone] = useState('')

  const [sendConfModal, setSendConfModal] = useState(false)
  const usr = JSON.parse(localStorage.getItem('acc'))

  const sendConfirmation = async (driverPhone, driverName) => {
    const body = {
      data: {
        link: link,
        acc: usr,
        orders: selectedItems,
        driverPhone,
        driverName,
      },
    }

    await sendOrdersToDriverTemplate(body)
    logAction('Send confirmation', {
      orderIds: selectedItems.map((item) => item._id),
      details: {
        name: driverName,
        phone: driverPhone,
      },
      user: {
        ...usr,
        actions: {},
      },
    })
    dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
    setSelectedItems([])
    setSendConfModal(false)
  }

  return (
    <div onClick={() => setSendConfModal(true)} className='tableModal__body__item'>
      <WhatsAppIcon
        sx={{
          width: '30px',
          height: '30px',
        }}
      />
      {/* <p>WhatsApp</p> */}
      <Modal
        open={sendConfModal}
        onClose={(e) => {
          e.stopPropagation()
          setSendConfModal(false)
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: 300,
            maxHeight: '80vh',
            // height: 300,
            width: '300px',
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            borderRadius: '10px',
            boxShadow: 24,
            padding: '15px',
            overflow: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', maxHeight: '300px', overflow: 'auto', padding: '10px' }}>
            {drivers
              ? drivers.map((driver) => (
                  <Box
                    sx={{
                      fontSize: '16px',
                      textAlign: 'left',
                      marginBottom: '10px',
                      padding: '5px',
                      ':hover': { cursor: 'pointer', backgroundColor: 'gray', borderRadius: '5px' },
                    }}
                    key={driver._id}
                    onClick={() => sendConfirmation(driver.phone, driver.name)}
                  >
                    {driver.name}
                  </Box>
                ))
              : null}
          </Box>
          <Box>
            <Button onClick={() => setChangeModal(true)} variant='contained' fullWidth>
              Edit
            </Button>
            <Modal
              open={changeModal}
              onClose={() => {
                setChangeModal(false)
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 300,
                  height: 300,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Select value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)} sx={{ width: '100%' }}>
                  {drivers &&
                    drivers.map((driver) => (
                      <MenuItem key={driver._id} value={driver}>
                        {driver.name}
                      </MenuItem>
                    ))}
                </Select>

                <TextField
                  value={selectedContactName}
                  onChange={(e) => setSelectedContactName(e.target.value)}
                  sx={{ width: '100%' }}
                  // label='Name'
                />
                <TextField
                  value={selectedContactPhone}
                  onChange={(e) => setSelectedContactPhone(e.target.value)}
                  sx={{ width: '100%' }}
                  // label='Name'
                />

                <Button onClick={() => handleUpdateDriver()} variant='contained' fullWidth>
                  Save
                </Button>
                <Button onClick={() => handleDeleteDriver()} variant='contained' color='error' fullWidth>
                  Delete
                </Button>
              </Box>
            </Modal>

            <Button onClick={() => setCreateModal(true)} sx={{ marginTop: '10px' }} variant='contained' fullWidth>
              Add new
            </Button>
            <Modal open={createModal} onClose={() => setCreateModal(false)}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 300,
                  height: 300,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <TextField value={createName} onChange={(e) => setCreateName(e.target.value)} sx={{ width: '100%' }} label='Name' />
                <TextField value={createPhone} onChange={(e) => setCreatePhone(e.target.value)} sx={{ width: '100%' }} label='Phone' />
                <Button onClick={() => handleCreateDriver()} variant='contained' fullWidth>
                  Create
                </Button>
              </Box>
            </Modal>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
