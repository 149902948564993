import React, { useState } from 'react'
import { ReactComponent as Copy } from '../assets/svg/Copy.svg'
import { ReactComponent as Export } from '../assets/svg/Export.svg'
import { ReactComponent as Status } from '../assets/svg/Status.svg'
import { ReactComponent as NewOrder } from '../assets/svg/NewOrder.svg'
import { ReactComponent as Truck } from '../assets/svg/Truck.svg'
import { ReactComponent as Delete } from '../assets/svg/Delete.svg'
import { mainApi } from '../store/services/mainApi'
import { useDispatch } from 'react-redux'
import { Box, Button, Modal, TextField } from '@mui/material'
import { logAction } from './logsAction/logService'

export function renderModal(key, selectedItems, setSelectedItems) {
  const [deleteContact] = mainApi.useDeleteContactMutation()
  const dispatch = useDispatch()

  const [statusModal, setStatusModal] = useState(false)

  const [password, setPassword] = useState('')

  const [dummyModal, setDummyModal] = useState(false)

  const handleDeleteItems = (e) => {
    if (password === '359') {
      deleteContacts(selectedItems, setSelectedItems, deleteContact, dispatch)
      e.stopPropagation()
      setDummyModal(false)
    } else {
      alert('Wrong password')
    }
  }

  switch (key) {
    case 'Copy':
      return (
        <div className='tableModal__body__item'>
          <Copy width={30} height={30} />
          <p>Copy</p>
        </div>
      )
    case 'Export':
      return (
        <div onClick={() => exportContactsHndle(selectedItems, setSelectedItems)} className='tableModal__body__item'>
          <Export width={30} height={30} />
          <p>Export</p>
        </div>
      )
    case 'Status':
      return (
        <div className='tableModal__body__item' onClick={() => setStatusModal(true)}>
          <Status width={30} height={30} />
          <p>Status</p>
          <Modal open={statusModal} onClose={() => setStatusModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
              }}
            ></Box>
          </Modal>
        </div>
      )
    case 'New contact':
      return (
        <div className='tableModal__body__item'>
          <NewOrder width={30} height={30} />
          <p>New order</p>
        </div>
      )
    case 'Add Route':
      return (
        <div className='tableModal__body__item'>
          <Truck width={30} height={30} />
          <p>Add Route</p>
        </div>
      )
    case 'Delete':
      return (
        <div className='tableModal__body__item' onClick={() => setDummyModal(true)}>
          <Delete width={30} height={30} />
          <p className='red'>Delete</p>
          <Modal open={dummyModal} onClose={() => setDummyModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 250,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>Are you sure?</p>
              <TextField
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id='outlined-basic'
                label='Password'
                variant='outlined'
              />
              <Box>
                <Button variant='contained' color='success' sx={{ m: 2 }} onClick={handleDeleteItems}>
                  Yes
                </Button>
                <Button
                  variant='contained'
                  color='error'
                  sx={{ m: 2 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setDummyModal(false)
                  }}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      )
  }
}

async function deleteContacts(selectedItems, setSelectedItems, deleteContact, dispatch) {
  console.log(selectedItems)
  const usr = JSON.parse(localStorage.getItem('acc'))
  await Promise.all(
    selectedItems.map(async (el) => {
      await deleteContact(el._id.toString()) // Pass the _id value as a string
    }),
  )
  logAction('Delete contacts', {
    contactIds: selectedItems.map((item) => item._id),
    user: {
      ...usr,
      actions: {},
    },
  })

  setSelectedItems([])
  dispatch(mainApi.util.invalidateTags(['Contacts']))
}
async function exportContactsHndle(selectedItems, setSelectedItems) {
  const selectedItemIds = selectedItems.map((item) => item._id)
  window.open('https://tpomobi.shop/exportContactsAdmin?_ids=' + selectedItemIds.join(','), '_blank')
  setSelectedItems([])
}
