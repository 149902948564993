import React, { useEffect, useMemo, useState } from 'react'
import '../../../../../Table/TableBody/TableBodyItem/TableBodyItem.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { useSelector, useDispatch } from 'react-redux'
import { changeItem } from '../../../../../../store/slices/changeItems'
import { Box, CircularProgress, Modal, Select, MenuItem } from '@mui/material'
import { mainApi } from '../../../../../../store/services/mainApi'
import ChangeStatus from '../../../../../ChangeStatus/ChangeStatus'
import { ReactComponent as Like } from '../../../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../../../assets/svg/inProgress.svg'
// import { ReactComponent as NotAvalible } from '../../../../../../assets/svg/NotAvalible.svg'
import { ReactComponent as Plus } from '../../../../../../assets/svg/Plus.svg'
import { ReactComponent as Production } from '../../../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../../../assets/svg/Congelador.svg'

import { useMediaQuery } from '@mui/material'

export default function OrderChangeTableBodyItem({
  favoriteItems,
  setFavoriteItems,
  selectedItems,
  setSelectedItems,
  data,
  options,
  keys,
  editOrder,
  order,
  progressItems,
  setProgressItems,
  productionItems,
  setProductionItems,
  shoppingItems,
  setShoppingItems,
  uniqueUnidads,
  isLoadingProducts,
}) {
  const mobileScreen = useMediaQuery('(max-width: 768px)')

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const save = useSelector((state) => state.changeItems.save)

  const {
    sku,
    enabled,
    _id: id,
    name,
    category,
    proveedor,
    unit,
    barcode,
    ourPrice,
    marketPrice,
    marketPriceDP,
    marketPriceSDP,
    marketPriceCP,
    IVA,
    quantity,
    quantityOrder,
    status,
    weightUnits,
    currentUnit,
    dias,
    currientRequest,
  } = data

  const { data: statusesData, isLoading } = mainApi.useGetAllStatusesQuery()

  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData.filter((status) => status.type === 'itemsInOrder'))
    }
  }, [statusesData])

  // change status in items

  //Modal

  const [statusModal, setStatusModal] = useState(false)

  const handleCloseStatusModal = () => {
    setStatusModal(false)
  }

  const handleOpenModal = () => {
    setStatusModal(!statusModal)
  }

  useEffect(() => {
    if (!editOrder) {
      setStatusModal(false)
    }
  }, [editOrder])

  const handleSelect = () => {
    if (selectedItems.includes(data)) {
      setSelectedItems(selectedItems.filter((item) => item !== data))
    } else {
      setSelectedItems([...selectedItems, data])
    }
  }

  const handleAddFavorite = () => {
    if (favoriteItems.includes(data)) {
      setFavoriteItems(favoriteItems)
    } else {
      setFavoriteItems([...favoriteItems, data])
    }
  }

  const handleAddProgress = () => {
    if (progressItems.includes(data)) {
      setProgressItems(progressItems)
    } else {
      setProgressItems([...progressItems, data])
    }
  }

  const handleAddProduction = () => {
    if (productionItems.includes(data)) {
      setProductionItems(productionItems)
    } else {
      setProductionItems([...productionItems, data])
    }
  }

  const handleAddShopping = () => {
    if (shoppingItems.includes(data)) {
      setShoppingItems(shoppingItems)
    } else {
      setShoppingItems([...shoppingItems, data])
    }
  }

  const [changestatus, setChangeStatus] = useState(status)
  const [changeName, setChangeName] = useState(name)
  const [changeCategory, setChangeCategory] = useState(category)
  const [changeUnit, setChangeUnit] = useState(currentUnit)
  const [changeProveedor, setChangeProveedor] = useState(proveedor)
  const [changeQuantity, setChangeQuantity] = useState(quantity)
  const [changeIVA, setChangeIVA] = useState(IVA)
  const [changeBarcode, setChangeBarcode] = useState(barcode)
  const [changeOurPrice, setChangeOurPrice] = useState(ourPrice)
  const [changeMarketPriceCP, setChangeMarketPriceCP] = useState(marketPriceCP)
  const [changeMarketPriceDP, setChangeMarketPriceDP] = useState(marketPriceDP)
  const [changeMarketPriceSDP, setChangeMarketPriceSDP] = useState(marketPriceSDP)
  const [changeMarketPriceMDP, setChangeMarketPriceMDP] = useState(marketPrice)
  const [changeSKU, setChangeSKU] = useState(sku)
  const [changeQuantityOrder, setChangeQuantityOrder] = useState(quantityOrder)
  const [changeCurrientRequest1, setChangeCurrientRequest1] = useState(currientRequest ? currientRequest.split(' ')[0] : quantityOrder)
  const [changeCurrientRequest2, setChangeCurrientRequest2] = useState(currientRequest ? currientRequest.split(' ')[1] : currentUnit)

  useEffect(() => {
    if (save) {
      dispatch(
        changeItem({
          ...data,
          name: changeName,
          category: changeCategory,
          proveedor: changeProveedor,
          currentUnit: changeUnit,
          barcode: changeBarcode,
          ourPrice: +changeOurPrice,
          marketPrice: +changeMarketPriceMDP,
          marketPriceDP: +changeMarketPriceDP,
          marketPriceSDP: +changeMarketPriceSDP,
          marketPriceCP: +changeMarketPriceCP,
          IVA: +changeIVA ? +changeIVA : null,
          quantity: +changeQuantity,
          status: changestatus,
          sku: changeSKU,
          quantityOrder: +changeQuantityOrder,
          currientRequest: changeCurrientRequest1 + ' ' + changeCurrientRequest2,
        }),
      )
    }
  }, [save])

  const statusBackground = useMemo(() => {
    switch (changestatus.name) {
      case 'Ready':
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        handleAddFavorite()
        return <Like width={20} height={20} />
      case 'PROCESSING':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        handleAddProgress()
        return <InProgress width={20} height={20} />
      case 'NOT AVAILABLE':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }

        return '⛔'
      case 'CHECKING':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        handleAddProgress()
        return '👁️'
      case 'PRODUCTION':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        handleAddProduction()
        return <Production width={20} height={20} /> //Production
      case 'SHOPPING':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        handleAddShopping()
        return <Delivery fill='#6D26E1' width={20} height={20} />
      case 'CONGEDOR':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        handleAddProgress()
        return <CONGEDOR width={20} height={20} />
      case 'DEVOLUCION':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        return '🔙'
      case 'CONFIRMAR':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        handleAddProgress()
        return '👍'
      case 'RESERVED':
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        return '📌'
      default:
        if (favoriteItems.includes(data)) {
          setFavoriteItems(favoriteItems.filter((item) => item !== data))
        }
        if (productionItems.includes(data)) {
          setProductionItems(productionItems.filter((item) => item !== data))
        }
        if (shoppingItems.includes(data)) {
          setShoppingItems(shoppingItems.filter((item) => item !== data))
        }
        if (progressItems.includes(data)) {
          setProgressItems(progressItems.filter((item) => item !== data))
        }
        return null
    }
  }, [changestatus, data])

  useEffect(() => {
    console.log('change status', data.status)

    setChangeStatus(data.status)
  }, [order])

  const visible = useMemo(() => {
    const count = Object.values(options).reduce((acc, item) => {
      if (item) {
        return acc + 1
      }
      return acc
    }, 0)
    return count
  }, [options])

  //profits and colors
  const profitPercent = useMemo(() => {
    return (findProfit(data) / findTotal(data)) * 100
  }, [data])

  //image handler
  const [openImg, setOpenImg] = useState(false)

  const handleOpenImg = () => {
    setOpenImg(true)
  }

  const handleCloseImg = () => {
    setOpenImg(false)
  }

  return (
    <div
      className={`tableBodyItem `}
      style={{
        backgroundColor:
          profitPercent <= 0
            ? '#f53e31'
            : profitPercent < 30
            ? '#fce8e8'
            : profitPercent > 70
            ? '#fcea5b'
            : profitPercent >= 50
            ? '#fcf3ac'
            : '#ecffed',
      }}
    >
      <div
        style={{
          width: '70px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'sticky',
          left: 0,
          zIndex: 10,
          backgroundColor: profitPercent < 30 ? '#fce8e8' : profitPercent > 70 ? '#fcea5b' : profitPercent >= 50 ? '#fcf3ac' : '#ecffed',
        }}
      >
        <input
          style={{ position: 'relative', top: '1px', left: '1px' }}
          type='checkbox'
          className='tableBody__title__checkbox'
          onChange={handleSelect}
          checked={selectedItems.includes(data)}
        />
        {/* <input type='checkbox' className='tableBody__title__checkbox like' onChange={handleAddFavorite}
                 checked={favoriteItems.includes(data)}/> */}
        <div
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '7px',
            background: '#fff',
            border: '2px solid #06c0b0',

            color: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            top: '1px',
            cursor: 'pointer',

            //   background: status ? '#06c0b0' : '#fff',
          }}
          onClick={() => {
            if (editOrder) {
              handleOpenModal()
            }
          }}
        >
          {statusBackground}

          {mobileScreen ? (
            <Modal open={statusModal} onClose={handleOpenModal}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 300,
                  height: 300,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <div
                  className='itemStatus'
                  style={{
                    display: statusModal && editOrder ? 'grid' : 'none',
                    gridTemplateColumns: '1fr 1fr',
                    width: '100%',
                    justifyContent: 'space-between',
                    height: '300px',
                    position: 'relative',
                    top: '50%',
                    left: '0',
                    overflow: 'auto',
                    transform: 'translate(0, -50%)',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                >
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584a9da0ca272b8a1c94df4'))}
                    className='itemStatus__item'
                  >
                    <Like width={24} height={24} />
                    <span>READY</span>
                  </div>
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584ab230ca272b8a1c94df9'))}
                    className='itemStatus__item'
                  >
                    <Delivery width={24} height={24} />
                    <span>SHOPPING</span>
                  </div>
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '65a1cd7688c2e6224aa96662'))}
                    className='itemStatus__item'
                  >
                    <p>👍</p>
                    <span>Confirmar</span>
                  </div>
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584aad40ca272b8a1c94df7'))}
                    className='itemStatus__item'
                  >
                    {/* <Checking width={24} height={24} /> */}
                    <p>👁️</p>
                    <span>CHECKING</span>
                  </div>
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584ab060ca272b8a1c94df8'))}
                    className='itemStatus__item'
                  >
                    <Production width={24} height={24} />
                    <span>PRODUCTION</span>
                  </div>

                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '658efe96c582a56609d72de8'))}
                    className='itemStatus__item'
                  >
                    <CONGEDOR width={24} height={24} />
                    <span>CONGELADOR</span>
                  </div>
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584aa570ca272b8a1c94df6'))}
                    className='itemStatus__item'
                  >
                    <p>⛔</p>
                    <span>NOT AVAILABLE</span>
                  </div>
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584aa210ca272b8a1c94df5'))}
                    className='itemStatus__item'
                  >
                    <InProgress width={24} height={24} />
                    <span>PARCIAL</span>
                  </div>
                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '65959de07503ef32164ba38c'))}
                    className='itemStatus__item'
                  >
                    <p>🔙</p>
                    <span>DEVOLUCION</span>
                  </div>

                  <div
                    style={{ border: '1px solid #000' }}
                    onClick={() => setChangeStatus(statuses.find((status) => status._id === '66967e23563e309ef4688dba'))}
                    className='itemStatus__item'
                  >
                    <p>📌</p>
                    <span>RESERVED</span>
                  </div>

                  <div onClick={() => setChangeStatus({})} className='itemStatus__add'>
                    Delete
                    <Plus
                      width={15}
                      height={15}
                      style={{
                        position: 'relative',
                        // top: '1px',
                        left: '12px',
                      }}
                    />
                  </div>
                </div>
              </Box>
            </Modal>
          ) : (
            <div
              className='itemStatus'
              style={{
                display: statusModal && editOrder ? 'flex' : 'none',
              }}
            >
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584a9da0ca272b8a1c94df4'))} className='itemStatus__item'>
                <Like width={24} height={24} />
                <span>READY</span>
              </div>
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584ab230ca272b8a1c94df9'))} className='itemStatus__item'>
                <Delivery width={24} height={24} />
                <span>SHOPPING</span>
              </div>
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '65a1cd7688c2e6224aa96662'))} className='itemStatus__item'>
                <p>👍</p>
                <span>Confirmar</span>
              </div>
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584aad40ca272b8a1c94df7'))} className='itemStatus__item'>
                {/* <Checking width={24} height={24} /> */}
                <p>👁️</p>
                <span>CHECKING</span>
              </div>
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584ab060ca272b8a1c94df8'))} className='itemStatus__item'>
                <Production width={24} height={24} />
                <span>PRODUCTION</span>
              </div>

              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '658efe96c582a56609d72de8'))} className='itemStatus__item'>
                <CONGEDOR width={24} height={24} />
                <span>CONGELADOR</span>
              </div>
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584aa570ca272b8a1c94df6'))} className='itemStatus__item'>
                <p>⛔</p>
                <span>NOT AVAILABLE</span>
              </div>
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '6584aa210ca272b8a1c94df5'))} className='itemStatus__item'>
                <InProgress width={24} height={24} />
                <span>PARCIAL</span>
              </div>
              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '65959de07503ef32164ba38c'))} className='itemStatus__item'>
                <p>🔙</p>
                <span>DEVOLUCION</span>
              </div>

              <div onClick={() => setChangeStatus(statuses.find((status) => status._id === '66967e23563e309ef4688dba'))} className='itemStatus__item'>
                <p>📌</p>
                <span>RESERVED</span>
              </div>

              <div onClick={() => setChangeStatus({})} className='itemStatus__add'>
                Delete
                <Plus
                  width={15}
                  height={15}
                  style={{
                    position: 'relative',
                    // top: '1px',
                    left: '12px',
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{ display: options[keys[0].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%`, textAlign: 'left' }}
        className='tableBodyItem__item'
        // data-tooltip={``}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>ID:</p>} */}
        <span className='hover'>{id ? id : ''}</span>
      </div>
      <div
        style={{
          display: options[keys[1].toLowerCase()] ? 'block' : 'none',
          width: `${80 / visible}%`,
          textAlign: 'left',
        }}
        className='tableBodyItem__item'
        data-tooltip={sku ? sku : ''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>SKU:</p>} */}
        {editOrder && changestatus?._id === '6584aad40ca272b8a1c94df7' ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeSKU}
            onChange={(e) => setChangeSKU(e.target.value)}
          />
        ) : (
          <span style={{ textAlign: 'left', display: 'flex' }} className='hover'>
            {dias && (
              <span
                style={{
                  display: 'flex',
                  flexShrink: 0,
                  border: '1px solid #407bff',
                  color: '#407bff',
                  backgroundColor: '#f0f7ff',
                  fontFamily: 'Montserrat',
                  width: '15px',
                  height: '15px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  marginRight: '5px',
                }}
              >
                {dias}
              </span>
            )}
            {sku ? sku : ''}
          </span>
        )}
      </div>
      <div
        style={{ display: options[keys[2].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        // data-tooltip={''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Barcode:</p>} */}
        <span className='hover'>{barcode ? barcode : ''}</span>
      </div>
      <div
        style={{ display: options[keys[3].toLowerCase()] ? 'block' : 'none', width: `${5}%` }}
        className='tableBodyItem__item platform'
        data-tooltip={mobileScreen ? 'Sold:' + quantity : ''}
      >
        {data?.img ? (
          <img
            style={{ width: '50px', height: '50px' }}
            src={data.img}
            alt={data.name}
            onError={(e) => (e.target.style.display = 'none')}
            onClick={handleOpenImg}
          />
        ) : (
          <img
            style={{ width: '50px', height: '50px' }}
            src={'https://imgur.com/b6ByMHk.jpg'}
            alt={data.name}
            onError={(e) => (e.target.style.display = 'none')}
          />
        )}
        <Modal open={openImg} onClose={handleCloseImg}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: mobileScreen ? 300 : 600,
              height: '100%',
              backgroundImage: `url(${data.img})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              cursor: 'pointer',
            }}
            onClick={() => {
              window.open(`https://tpoapp.com/item/${data._id}`, '_blank')
            }}
          ></Box>
        </Modal>
      </div>
      {/*<div style={{width: '100px', display: options[keys[20].toLowerCase()] ? 'block' : 'none'}}*/}
      {/* className='tableBodyItem__item platform' data-tooltip={''}> */}
      {/*    Status*/}
      {/*</div>*/}
      {/* <div style={{}} className={`tableBodyItem__item  `}>
        <div
          onClick={handleOpenModal}
          style={{ backgroundColor: status ? status.color : '', display: options[keys[4].toLowerCase()] ? 'block' : 'none' }}
          className='tableBodyItem__item status'
        >
          <span style={{ textAlign: 'center' }}>{statusName ? statusName : 'Add'}</span>
          
        </div>
      </div> */}
      <div
        style={{ display: options[keys[4].toLowerCase()] ? 'block' : 'none', width: `${20}%`, textAlign: 'left' }}
        className='tableBodyItem__item'
        data-tooltip={name ? name + ' - ' + proveedor : ''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Name:</p>} */}
        {editOrder && changestatus?._id === '6584aad40ca272b8a1c94df7' ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeName}
            onChange={(e) => setChangeName(e.target.value)}
          />
        ) : (
          <span className='hover' style={{ textAlign: 'left' }}>
            {name ? name : ''}
          </span>
        )}
      </div>
      <div style={{ display: options[keys[5].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Category:</p>} */}

        {editOrder ? (
          // <TextField
          //   sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
          //   id='standard-basic'
          //   variant='outlined'
          //   value={changeCategory}
          //   onChange={(e) => setChangeCategory(e.target.value)}
          // />
          <span className='hover' style={{ textAlign: 'left' }}>
            {category ? category : ''}
          </span>
        ) : (
          <span className='hover' style={{ textAlign: 'left' }}>
            {category ? category : ''}
          </span>
        )}
      </div>
      <div style={{ display: options[keys[6].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Proveedor:</p>} */}

        {editOrder ? (
          // <TextField
          //   sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
          //   id='standard-basic'
          //   variant='outlined'
          //   value={changeProveedor}
          //   onChange={(e) => setChangeProveedor(e.target.value)}
          // />
          <span className='hover' style={{ textAlign: 'left' }}>
            {proveedor ? proveedor : ''}
          </span>
        ) : (
          <span className='hover' style={{ textAlign: 'left' }}>
            {proveedor ? proveedor : ''}
          </span>
        )}
      </div>
      <div style={{ display: options[keys[7].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Unit:</p>} */}

        {editOrder ? (
          isLoadingProducts ? (
            <CircularProgress />
          ) : (
            <Select
              sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
              id='standard-basic'
              variant='outlined'
              size='small'
              value={changeUnit}
              onChange={(e) => setChangeUnit(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '200px', // Set the desired height for the dropdown menu
                  },
                },
              }}
            >
              {uniqueUnidads?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          )
        ) : (
          <span className='hover'>{changeUnit ? changeUnit : ''}</span>
        )}
      </div>
      <div
        data-tooltip={editOrder ? '' : currientRequest ? currientRequest : quantityOrder + ' ' + currentUnit}
        style={{ display: options[keys[8].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Requested:</p>} */}

        {editOrder ? (
          // <TextField
          //   sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
          //   id='standard-basic'
          //   variant='outlined'
          //   size='small'
          //   value={changeQuantityOrder}
          //   onChange={(e) => setChangeQuantityOrder(e.target.value)}
          // />
          // <span className='hover'>{currientRequest ? currientRequest : quantityOrder + ' ' + currentUnit + '*'}</span>
          <Box>
            <TextField
              sx={{ width: '50px', fontFamily: 'Inter', fontWeight: '300' }}
              defaultValue={currientRequest ? currientRequest.split(' ')[0] : quantityOrder}
              onChange={(e) => setChangeCurrientRequest1(e.target.value)}
              id='standard-basic'
              variant='standard'
              size='small'
            />
            <TextField
              defaultValue={currientRequest ? currientRequest.split(' ')[1] : currentUnit}
              onChange={(e) => setChangeCurrientRequest2(e.target.value)}
              sx={{ width: '50px', fontFamily: 'Inter', fontWeight: '300' }}
              id='standard-basic'
              variant='standard'
              size='small'
            />
          </Box>
        ) : (
          <span className='hover'>{currientRequest ? currientRequest : quantityOrder + ' ' + currentUnit + '*'}</span>
        )}
      </div>
      <div style={{ display: options[keys[9].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Sold:</p>} */}

        {editOrder ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeQuantity}
            onChange={(e) => setChangeQuantity(e.target.value)}
          />
        ) : (
          <span className='hover'>{data.quantity ? data.quantity : ''}</span>
        )}
      </div>
      <div style={{ display: options[keys[10].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>IVA:</p>} */}

        {editOrder ? (
          <Select value={changeIVA ? changeIVA : '0'} onChange={(e) => setChangeIVA(e.target.value)}>
            <MenuItem value={'0'}>0%</MenuItem>
            <MenuItem value={'2'}>2%</MenuItem>
            <MenuItem value={'4'}>4%</MenuItem>
            <MenuItem value={'5'}>5%</MenuItem>
            <MenuItem value={'10'}>10%</MenuItem>
            <MenuItem value={'21'}>21%</MenuItem>
          </Select>
        ) : (
          <span className='hover'>{data.IVA ? data.IVA : '0'}%</span>
        )}
      </div>
      <div
        style={{ display: options[keys[12].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        // data-tooltip={''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>COGS:</p>} */}

        {editOrder ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeMarketPriceMDP}
            onChange={(e) => setChangeMarketPriceMDP(e.target.value)}
          />
        ) : (
          <span className='hover'>{marketPrice ? marketPrice : ''} &euro;</span>
        )}
      </div>
      <div style={{ display: options[keys[11].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>MBP:</p>} */}

        {editOrder ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeOurPrice}
            onChange={(e) => setChangeOurPrice(e.target.value)}
          />
        ) : (
          <span className='hover'>{ourPrice ? ourPrice.toFixed(2) : ''}&euro;</span>
        )}
      </div>

      <div style={{ display: options[keys[13].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>DP:</p>} */}

        {editOrder ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeMarketPriceDP}
            onChange={(e) => setChangeMarketPriceDP(e.target.value)}
          />
        ) : (
          <span className='hover'>{marketPriceDP ? marketPriceDP : ''}&euro;</span>
        )}
      </div>
      <div
        style={{ display: options[keys[14].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        // data-tooltip={''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>SDP:</p>} */}

        {editOrder ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeMarketPriceSDP}
            onChange={(e) => setChangeMarketPriceSDP(e.target.value)}
          />
        ) : (
          <span className='hover'>{marketPriceSDP ? marketPriceSDP : ''}&euro;</span>
        )}
      </div>
      <div
        style={{ display: options[keys[15].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        // data-tooltip={''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Price:</p>} */}

        {editOrder ? (
          <TextField
            sx={{ width: '100px', fontFamily: 'Inter', fontWeight: '300' }}
            id='standard-basic'
            variant='outlined'
            size='small'
            value={changeMarketPriceCP}
            onChange={(e) => setChangeMarketPriceCP(e.target.value)}
          />
        ) : (
          <span className='hover'>{marketPriceCP ? marketPriceCP : ''}&euro;</span>
        )}
      </div>
      <div
        style={{ display: options[keys[16].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        // data-tooltip={''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Profit:</p>} */}

        <span className='hover'>{findProfit(data)}&euro;</span>
      </div>
      <div
        style={{ display: options[keys[17].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        // data-tooltip={''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Margen:</p>} */}

        <span className='hover'>{profitPercent.toFixed(2)}%</span>
      </div>
      <div
        style={{ display: options[keys[18].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
        // data-tooltip={''}
      >
        {/* {mobileScreen && <p className='tableBodyItem__item__mobileTitle'>Total:</p>} */}

        <span className='hover'>{findTotal(data)}&euro;</span>
      </div>

      {/* <div
        style={{ display: options[keys[19].toLowerCase()] ? 'block' : 'none', width: `${80 / visible}%` }}
        className='tableBodyItem__item'
      >

        <span className='hover'>{currientRequest ? currientRequest : quantityOrder + ' ' + currentUnit + '*'}</span>
      </div> */}
    </div>
  )
}

export const findProfit = (data) => {
  const marketPrice = parseFloat(data.marketPriceCP)
  const quantity = data.quantity || 0
  const ourPrice = data.ourPrice || 0

  if (isNaN(marketPrice)) {
    return '0.00'
  }

  const revenue = marketPrice * quantity
  const cost = ourPrice * quantity

  const profit = revenue - cost

  return profit.toFixed(2)
}

// export const findProfit = (data) => {
//   const marketPrice = parseFloat(data.marketPriceCP)
//   const quantity = data.quantity || 1
//   const ourPrice = data.ourPrice || 0
//   const IVA = data.IVA || 0

//   if (isNaN(marketPrice)) {
//     return '0.00'
//   }

//   const revenue = marketPrice * quantity
//   const cost = ourPrice * quantity
//   const taxReduction = 1 - IVA / 100

//   const profit = (revenue - cost) * taxReduction

//   return profit.toFixed(2)
// }

export const findMargin = (data) => {
  const quantity = data.quantity || 1
  const marketPrice = parseFloat(data.marketPriceCP * quantity)
  const ourPrice = parseFloat(data.ourPrice * quantity)
  const iva = parseFloat(data.IVA || 0)

  const profit = marketPrice - ourPrice
  const totalPrice = ourPrice + (ourPrice * iva) / 100

  const margin = ((profit / totalPrice) * 100).toFixed(2)

  return isNaN(margin) ? '0.00' : margin
}
export const findTotal = (data) => {
  const marketPrice = parseFloat(data.marketPriceCP)
  const quantity = parseFloat(data.quantity || 1)

  const total = marketPrice * quantity

  return !isNaN(total) ? total.toFixed(2) : '0.00'
}
