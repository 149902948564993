import {
    Box,
    CircularProgress,
    Container,
    TextField,
    Snackbar,
    FormControl,
    Button
} from '@mui/material'


import { ReactComponent as Like } from '../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../assets/svg/Congelador.svg'


import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { mainApi } from '../../store/services/mainApi'

import JuiceProductionAccordion from "./Switch/JuiceProductionAccordion";
import JuiceProductionList from "./Switch/JuiceProductionList";




export default function JuiceProduction() {
    useEffect(() => {
        document.title = 'JuiceProduction'
    }, [])
    const [selectedDeliveryDates, setSelectedDeliveryDates] = useState([]);

    const [openRows, setOpenRows] = useState({});
    const [selectedItems, setSelectedItems] = useState([]); // Holds selected items

    const [switchChecker, setSwitchChecker] = useState(false);
    const [isSortedByNewest, setIsSortedByNewest] = useState(true);

    const [buttonFilters, setButtonFilters] = useState({
        PLM: false,
        PLT: false,
        Granada: false,
        Naranja: false,
        Citrico: false,
        GINGER: false,
        LIMA: false,
        LIMON: false,
        POMELO: false,
        Mandarina: false,
        Jengibre: false
    });


    const toggleButtonFilter = (filterName) => {
        setButtonFilters(prevState => ({
            ...prevState,
            [filterName]: !prevState[filterName]
        }));
    };

    const [search, setSearch] = useState('')



    const handleAllItemsSelect = () => {
        if(selectedItems.length == allItemsArray.length){
            setSelectedItems([]);
        }else{
            setSelectedItems(allItemsArray)
        }
    }

    const handleNestedSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemExists = prevItems.some(i =>
                (i._id + i.items._id) === (item._id + item.items._id)
            );

            if (isChecked) {
                // Add item if checked and not already in selectedItems
                if (!itemExists) {
                    return [...prevItems, item];
                }
                return prevItems;
            } else {
                // Remove item if unchecked
                return prevItems.filter(i =>
                    (i._id + i.items._id) !== (item._id + item.items._id)
                );
            }
        });
    };

    const handleTopLevelSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = item.orders.map(order => order._id + order.items._id);

            if (isChecked) {
                // Add all orders from this item if checked
                const newItems = item.orders.filter(order =>
                    !prevItems.some(i => (i._id + i.items._id) === (order._id + order.items._id))
                );
                return [...prevItems, ...newItems];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };

    const handleTopLevelSelectAlone = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = [item._id + item.items._id]

            if (isChecked) {
                // Add all orders from this item if checked
                return [...prevItems, item];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };



// Toggle open state for individual rows
    const toggleRowOpen = (itemId) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [itemId]: !prevOpenRows[itemId]
        }));
    };


    const { data: uniqueZumos, isLoading } = mainApi.useGetZumosQuery(
        {

        },
        // { pollingInterval: 5000 },
    )




    const normalizeText = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();



    const rows = useMemo(() => {
        if (!isLoading) {
            // Фильтрация по заданным критериям
            const filteredRows = uniqueZumos.map(el => {
                // Фильтруем заказы по PLM, PLT и выбранным `deliveryDate`
                const filteredOrders = el.orders.filter(e => {
                    const matchesPLM = buttonFilters.PLM ? e.deliveryTime.includes("PLM") : true;
                    const matchesPLT = buttonFilters.PLT ? e.deliveryTime.includes("PLT") : true;

                    // Фильтр по `deliveryDate`, если установлены `selectedDeliveryDates`
                    const matchesDeliveryDate = selectedDeliveryDates.length
                        ? selectedDeliveryDates.some(date => e.deliveryDate.includes(date))
                        : true;

                    return matchesPLM && matchesPLT && matchesDeliveryDate;
                });

                // Возвращаем элемент `el` с отфильтрованными `orders`, если они остались
                return filteredOrders.length > 0 ? { ...el, orders: filteredOrders } : null;
            }).filter(el => el !== null); // Убираем пустые элементы
            // Применение фильтров по другим кнопкам и поисковому запросу
            const fullyFilteredRows = filteredRows.filter(el => {
                // Проверка на остальные фильтры, кроме `PLM` и `PLT`
                const matchesOtherFilters = Object.keys(buttonFilters).every(filter => {
                    if (buttonFilters[filter] && filter !== 'PLM' && filter !== 'PLT') {
                        return el.orders.some(e =>
                            normalizeText(e.items.name).includes(normalizeText(filter))
                        );
                    }
                    return true;
                });

                // Фильтр по поисковому запросу
                const matchesSearch = el.orders.some(e =>
                    normalizeText(e.items.name).includes(normalizeText(search)) ||
                    normalizeText(e.tradeName).includes(normalizeText(search))
                );

                return matchesOtherFilters && matchesSearch;
            });

            // Сортировка по дате `e.date`
            return fullyFilteredRows.sort((a, b) => {
                const dateA = Math.max(...a.orders.map(e => e.date));
                const dateB = Math.max(...b.orders.map(e => e.date));
                return isSortedByNewest ? dateB - dateA : dateA - dateB;
            });
        }
        return [];
    }, [uniqueZumos, search, buttonFilters, isLoading, isSortedByNewest, selectedDeliveryDates]);


    const fakeRows = useMemo(() => {
        // Шаг 1: Модификация sku для каждого элемента
        const modifiedRows = rows.map(item => ({
            ...item,
            sku: item.sku.replace("plast", "").replaceAll("1", "")
        }));

        // Шаг 2: Объединение элементов с одинаковым sku
        const mergedRows = modifiedRows.reduce((acc, item) => {
            const existingItem = acc.find(i => i.sku === item.sku);

            if (existingItem) {
                // Объединяем свойства для одинаковых sku
                existingItem.orders = existingItem.orders.concat(item.orders);
                existingItem.totalPrice += item.totalPrice;
                existingItem.totalQuantity += item.totalQuantity;
            } else {
                // Если элемента с таким sku еще нет, добавляем его в массив
                acc.push({ ...item });
            }

            return acc;
        }, []);

        // Выводим результат в консоль для проверки
        mergedRows.forEach(e => console.log(e));

        return mergedRows;
    }, [rows]);

    const allItemsArray = useMemo(() => {
            if(!isLoading){
                return rows.map(el => el.orders.map(e => e)).flat();
            }

        }, [rows])




    const toggleDeliveryDateFilter = (date) => {
        setSelectedDeliveryDates(prevDates =>
            prevDates.includes(date)
                ? prevDates.filter(d => d !== date)
                : [...prevDates, date]
        );
    };

    console.log("selectedDeliveryDates", selectedDeliveryDates)

    const statusBackground = (status) => {
        switch (status) {
            case 'Ready':
                return <Like width={20} height={20} />
            case 'PROCESSING':
                return <InProgress width={20} height={20} />
            case 'NOT AVAILABLE':
                return '⛔'
            case 'CHECKING':
                return '👁️'
            case 'PRODUCTION':
                return <Production width={20} height={20} /> //Production
            case 'SHOPPING':
                return <Delivery fill='#6D26E1' width={20} height={20} />
            case 'CONGEDOR':
                return <CONGEDOR width={20} height={20} />
            case 'DEVOLUCION':
                return '🔙'
            case 'CONFIRMAR':
                return '👍'
            default:
                return null
        }
    }

    const uniqueDeliveryDates = useMemo(() => {
            if(!isLoading){
                return [...new Set(allItemsArray.map(e => (e.deliveryDate.split(",")[0].replaceAll(", PLT🌙", "").replaceAll(", PLT 🌛", "").replaceAll(", PLM ☀️", ""))))]

            }
    }, [allItemsArray]);


    const optionsAutoComplete = useMemo(() => {
        if(!isLoading){
            return allItemsArray.map(el => {return {label: el.tradeName, id: el._id}})

        }

    }, [allItemsArray])





    return (
        <Container>
            <Box>
                <h1>Zumos Production</h1>
                <FormControl style={{display: 'flex'}}>
                    <TextField id="outlined-basic" label="Search" variant="outlined"
                               onChange={(e) => setSearch(e.target.value)}/>
                </FormControl>
                <FormControl style={{display: 'flex'}}>
                    <div style={{display: "flex", justifyContent: 'space-between', alignItems: 'center', flexWrap: "wrap"}}>
                        {!isLoading && uniqueDeliveryDates.map(date => (
                            <Button
                                key={date}
                                variant={selectedDeliveryDates.includes(date) ? "contained" : "outline"}
                                onClick={() => toggleDeliveryDateFilter(date)}
                            >
                                {date}
                            </Button>
                        ))}
                        <Button
                            variant={isSortedByNewest ? "contained" : "outline"}
                            onClick={() => setIsSortedByNewest(prev => !prev)}
                        >
                            {isSortedByNewest ? "Newest" : "Oldest"}
                        </Button>
                        <Button
                            variant={buttonFilters.PLM ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('PLM')}
                        >
                            PLM ☀️
                        </Button>

                        <Button
                            variant={buttonFilters.PLT ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('PLT')}
                        >
                            PLT 🌛
                        </Button>

                        <Button
                            variant={buttonFilters.Granada ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('Granada')}
                        >
                            Granada
                        </Button>

                        <Button
                            variant={buttonFilters.Naranja ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('Naranja')}
                        >
                            Naranja
                        </Button>

                        <Button
                            variant={buttonFilters.Citrico ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('Citrico')}
                        >
                            Citrico
                        </Button>

                        <Button
                            variant={buttonFilters.LIMA ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('LIMA')}
                        >
                            LIMA
                        </Button>

                        <Button
                            variant={buttonFilters.LIMON ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('LIMON')}
                        >
                            LIMON
                        </Button>

                        <Button
                            variant={buttonFilters.POMELO ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('POMELO')}
                        >
                            POMELO
                        </Button>

                        <Button
                            variant={buttonFilters.Mandarina ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('Mandarina')}
                        >
                            Mandarina
                        </Button>

                        <Button
                            variant={buttonFilters.Jengibre ? "contained" : "outline"}
                            onClick={() => toggleButtonFilter('Jengibre')}
                        >
                            Jengibre
                        </Button>

                    </div>
                </FormControl>
                {isLoading ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </Box>
                ) : switchChecker
                    ? (
                        <JuiceProductionAccordion allItemsArray={allItemsArray}
                                                  fakeRows={fakeRows}
                                                  statusBackground={statusBackground}
                                                  handleAllItemsSelect={handleAllItemsSelect}
                                                  switchChecker={switchChecker} setSwitchChecker={setSwitchChecker}
                                                  rows={rows} selectedItems={selectedItems}
                                                  handleTopLevelSelect={handleTopLevelSelect}
                                                  toggleRowOpen={toggleRowOpen} openRows={openRows}
                                                  handleNestedSelect={handleNestedSelect}/>
                    )
                    :
                    (
                        <JuiceProductionList allItemsArray={allItemsArray}
                                             statusBackground={statusBackground}
                                             handleAllItemsSelect={handleAllItemsSelect}
                                             switchChecker={switchChecker} setSwitchChecker={setSwitchChecker}
                                             rows={rows} selectedItems={selectedItems}
                                             handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                                             toggleRowOpen={toggleRowOpen} openRows={openRows}
                                             handleNestedSelect={handleNestedSelect}/>
                    )


                }
            </Box>
            <Snackbar
                open={selectedItems.length}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                message={
                    "Selected: " + selectedItems.length +
                    " Total: " + selectedItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€" +
                    " AVG: " + (selectedItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0)/selectedItems.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0)).toFixed(2) + "€"

                }
            >
            </Snackbar>
            <Snackbar
                open={selectedItems.length}
                sx={{marginBottom: "50px"}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                message={
                    "Quantity: " + selectedItems.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0) +
                    " Plast: " + selectedItems.reduce((acc, item) => item.items.sku.includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0) +
                    " Crystal: " + selectedItems.reduce((acc, item) => !item.items.sku.includes('plast') ? acc + parseFloat(item.items.quantity) : acc, 0)
                }
            >
            </Snackbar>
        </Container>
    )
}



