import React, { useEffect, useMemo, useState } from 'react'
import './Catalog.scss'

import Stats from '../Stats/Stats'
import Table from '../Table/Table'

import { mainApi } from '../../store/services/mainApi'
import { CircularProgress, useMediaQuery } from '@mui/material'
import { renderComponent } from '../../utils/catalogFunctions'
import { renderModal } from '../../utils/catalogModal'
import CatalogTable from './CatalogTable/CatalogTable'
export default function Catalog() {
  useEffect(() => {
    document.title = 'Catalog'
  }, [])
  const mobileView = useMediaQuery('(max-width: 768px)')
  const { data: productsData, isLoading } = mainApi.useFindAllWordsQuery()
  const [products, setProducts] = useState([])
  const modalKeys = ['Copy', 'Export', 'Status', 'New product', 'Add Route', 'Delete', 'Total Items Price', 'Total Market Price']
  useEffect(() => {
    if (productsData) {
      setProducts(productsData)
      console.log(productsData)
    }
  }, [productsData])
  const enabledCount = useMemo(() => {
    return products.reduce((count, product) => {
      if (product.enabled) {
        return count + 1
      }
      return count
    }, 0)
  }, [products])
  const unEnabledCout = useMemo(() => {
    return products.length - enabledCount
  }, [products, enabledCount])
  const cogs = useMemo(() => {
    let total = 0
    products.forEach((product) => {
      total += product.ourPrice ? product.ourPrice : 0
    })
    return total
  }, [products])
  const marketPrice = useMemo(() => {
    let total = 0
    products.forEach((product) => {
      const marketPriceCP = parseFloat(product.marketPriceCP)
      if (!isNaN(marketPriceCP)) {
        total += marketPriceCP
      }
    })
    return total
  }, [products])
  const spoProducts = useMemo(() => {
    let total = 0
    products.forEach((product) => {
      if (product.SPOPrice) {
        total += 1
      }
    })
    return total
  }, [products])
  const mock = [
    {
      image: 'Cart',
      title: 'Total Products',
      value: products.length,
      color: 'rgb(92, 195, 253)',
    },
    {
      image: 'GreenCart',
      title: 'Enabled Products',
      value: enabledCount,
      color: 'rgb(0, 171, 85)',
    },
    {
      image: 'RedCart',
      title: 'Unabled Products',
      value: unEnabledCout,
      color: 'red',
    },
    {
      image: 'Cog',
      title: 'Entry Cogs',
      value: cogs.toFixed(2) + '€',
      color: '#0E8C15',
    },
    {
      image: 'Catalog',
      title: 'Catalog Price',
      value: marketPrice.toFixed(2) + '€',
      color: 'rgb(0, 171, 85)',
    },
    {
      image: 'Spo',
      title: 'SPO products',
      value: spoProducts,
      color: 'rgb(92, 195, 253)',
    },
  ]
  const keys = [
    'ID',
    'SKU',
    'Barcode',
    'Image',
    'Status',
    'Name',
    'Category',
    'Proveedor',
    'Unit',
    'IVA',
    'Entry Price',
    'MBP',
    'DP',
    'SDP',
    'CP',
    'Profit',
  ]
  const [options, setOptions] = useState({
    id: false,
    sku: true,
    barcode: false,
    image: true,
    status: true,
    name: true,
    category: false,
    proveedor: false,
    unit: true,
    iva: true,
    COGS: true,
    mbp: true,
    dp: true,
    sdp: true,
    cp: true,
    profit: true,
  })
  const handleChoose = (name) => {
    setOptions({
      ...options,
      [name]: !options[name],
    })
  }
  return localStorage.getItem("name") == "VPF" ? <div style={{textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        <h1>COMING SOON...</h1>
        <img
            src='https://media.giphy.com/media/XgXZ4LiyVKoM7Ikk1B/giphy.gif'
            alt='Snoop Dogg gif'
            style={{cursor: 'pointer'}}
        />
        <p>
          Also u can write me{' '}
          <a rel='noreferrer noopener' href='https://t.me/diffick'>
            LINK{' '}
          </a>
          and I will help to fix it
        </p>
      </div>
      : isLoading ? (
          <CircularProgress
              size={200}
              color='success'
              sx={{
                position: 'absolute',
                top: mobileView ? '50%' : '45%',
                left: mobileView ? '30%' : '45%',
                transform: 'translate(-50%, -50%)',
              }}
          />
      ) : (
          <div className='catalog'>
            <div className='catalog__title'>
              <Stats mock={mock}/>
              <CatalogTable
                  headerType={'products'}
                  renderModal={renderModal}
                  modalKeys={modalKeys}
                  options={options}
                  handleChoose={handleChoose}
                  keys={keys}
                  data={products}
                  renderComponent={renderComponent}
              />
            </div>
          </div>
      )
}
