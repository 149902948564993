import React, { useEffect, useState, useMemo } from 'react'
import './CatalogPrice.scss'
import { CircularProgress, TextField, Alert, Modal, Box, Typography, Button } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import { changeCurrentProduct, changeSave } from '../../../../store/slices/currentProduct'
import { findDifferences, logAction } from '../../../../utils/logsAction/logService'
import { use } from 'i18next'

export default function CatalogPrice({ setReset, product, reset }) {
  const [loading, setLoading] = useState(true)
  const [saveModal, setSaveModal] = useState(false)
  const [updateProduct, { isLoading, isSuccess, isError: isE }] = mainApi.useUpdateProductMutation()
  const dispatch = useDispatch()
  const currientProduct = useSelector((state) => state.currentProduct.currentProduct)
  const save = useSelector((state) => state.currentProduct.save)
  const usr = JSON.parse(localStorage.getItem('acc'))
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  //save modal

  const openSaveModal = () => {
    setSaveModal(true)
    dispatch(changeSave(true))
  }

  const closeSaveModal = () => {
    setSaveModal(false)
    dispatch(changeSave(false))
  }

  const [state, setState] = useState({
    spoActive: false,
    standart: true,
    spanSwitcher: false,
    marketPriceSPO: product.SPOPrice || 0,
    entryPriceSPO: product.ourPrice || 0,
    margentSPO: product.MBSPO || 0,
    IVASPO: product.IVASPO || 0,
    marketPrice: product.marketPrice || 0,
    entryPrice: product.ourPrice || 0,
    margent: product.MB || 0,
    IVA: product.IVA || 0,
    marketPriceDP: product.marketPriceDP || 0,
    entryPriceDP: product.ourPriceDP || 0,
    margentDP: product.DP || 0,
    IVADP: product.IVADP || 0,
    marketPriceSDP: product.marketPriceSDP || 0,
    entryPriceSDP: product.ourPriceSDP || 0,
    margentSDP: product.SDP || 0,
    IVASDP: product.IVASDP || 0,
    marketPriceCP: product.marketPriceCP || 0,
    entryPriceCP: product.ourPriceCP || 0,
    margentCP: product.CP || 0,
    IVACP: product.IVACP || 0,
  })

  useEffect(() => {
    if (isSuccess) {
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isE) {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 2000)
    }
  }, [isE])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      marketPriceSPO: product.SPOPrice || 0,
      entryPriceSPO: product.ourPrice || 0,
      margentSPO: product.MBSPO || 0,
      IVASPO: product.IVASPO || 0,
      marketPrice: product.marketPrice || 0,
      entryPrice: product.ourPrice || 0,
      margent: product.MB || 0,
      IVA: product.IVA || 0,
      marketPriceDP: product.marketPriceDP || 0,
      entryPriceDP: product.ourPriceDP || 0,
      margentDP: product.DP || 0,
      IVADP: product.IVADP || 0,
      marketPriceSDP: product.marketPriceSDP || 0,
      entryPriceSDP: product.ourPriceSDP || 0,
      margentSDP: product.SDP || 0,
      IVASDP: product.IVASDP || 0,
      marketPriceCP: product.marketPriceCP || 0,
      entryPriceCP: product.ourPriceCP || 0,
      margentCP: product.CP || 0,
      IVACP: product.IVACP || 0,
    }))
  }, [product])

  useEffect(() => {
    if (reset) {
      setState({
        spoActive: false,
        standart: true,
        spanSwitcher: true,
        marketPriceSPO: 0,
        entryPriceSPO: 0,
        margentSPO: 0,
        IVASPO: 0,
        marketPrice: 0,
        entryPrice: 0,
        margent: 0,
        IVA: 0,
        marketPriceDP: 0,
        entryPriceDP: 0,
        margentDP: 0,
        IVADP: 0,
        marketPriceSDP: 0,
        entryPriceSDP: 0,
        margentSDP: 0,
        IVASDP: 0,
        marketPriceCP: 0,
        entryPriceCP: 0,
        margentCP: 0,
        IVACP: 0,
      })
    }
  }, [reset])

  const calculatePrice = (entryPrice, margent, IVA) => {
    return (
      parseFloat(entryPrice) +
      (parseFloat(entryPrice) * parseFloat(margent)) / 100 +
      ((parseFloat(entryPrice) + (parseFloat(entryPrice) * parseFloat(margent)) / 100) * parseFloat(IVA)) / 100
    ).toFixed(2)
  }

  const calculateProfit = (currentPrice, entryPrice, IVA) => {
    return ((parseFloat(currentPrice) - parseFloat(entryPrice)) * (1 - parseFloat(IVA) / 100)).toFixed(2)
  }

  const calculateMargentProfit = (marketPrice, entryPrice, IVA) => {
    let withoutIVA =
      parseFloat(IVA) != 0
        ? parseFloat(marketPrice) - parseFloat((parseFloat(marketPrice) * (parseFloat(IVA) / 100)).toFixed(2))
        : parseFloat(marketPrice)
    let margentProfit = withoutIVA - parseFloat(entryPrice)
    return margentProfit.toFixed(2)
  }

  const calculateMargent = (marketPrice, entryPrice, IVA) => {
    let withoutIVA =
      parseFloat(IVA) != 0
        ? parseFloat(marketPrice) - parseFloat((parseFloat(marketPrice) * (parseFloat(IVA) / 100)).toFixed(2))
        : parseFloat(marketPrice)
    let margentProfit = withoutIVA - parseFloat(entryPrice)
    let margent = (parseFloat(margentProfit) / parseFloat(entryPrice)) * 100
    return margent.toFixed(2)
  }

  const currentPriceSPO = useMemo(
    () => calculatePrice(state.entryPriceSPO, state.margentSPO, state.IVA),
    [state.entryPriceSPO, state.margentSPO, state.IVA],
  )

  const currentProfitSPO = useMemo(
    () => calculateProfit(currentPriceSPO, state.entryPriceSPO, state.IVA),
    [currentPriceSPO, state.entryPriceSPO, state.IVA],
  )

  const currentMargentProfitSPO = useMemo(
    () => calculateMargentProfit(state.marketPriceSPO, state.entryPriceSPO, state.IVA),
    [state.marketPriceSPO, state.entryPriceSPO, state.IVA],
  )

  const currentMargentSPO = useMemo(
    () => calculateMargent(state.marketPriceSPO, state.entryPriceSPO, state.IVA),
    [state.marketPriceSPO, state.entryPriceSPO, state.IVA],
  )

  const currentPrice = useMemo(() => calculatePrice(state.entryPrice, state.margent, state.IVA), [state.entryPrice, state.margent, state.IVA])

  const currentProfit = useMemo(() => calculateProfit(currentPrice, state.entryPrice, state.IVA), [currentPrice, state.entryPrice, state.IVA])

  const currentMargentProfit = useMemo(
    () => calculateMargentProfit(state.marketPrice, state.entryPrice, state.IVA),
    [state.marketPrice, state.entryPrice, state.IVA],
  )

  const currentMargent = useMemo(
    () => calculateMargent(state.marketPrice, state.entryPrice, state.IVA),
    [state.marketPrice, state.entryPrice, state.IVA],
  )

  const currentPriceDP = useMemo(
    () => calculatePrice(state.entryPriceDP, state.margentDP, state.IVADP),
    [state.entryPriceDP, state.margentDP, state.IVADP],
  )

  const currentProfitDP = useMemo(
    () => calculateProfit(currentPriceDP, state.entryPriceDP, state.IVADP),
    [currentPriceDP, state.entryPriceDP, state.IVADP],
  )

  const currentMargentDPProfit = useMemo(
    () => calculateMargentProfit(state.marketPriceDP, state.entryPriceDP, state.IVADP),
    [state.marketPriceDP, state.entryPriceDP, state.IVADP],
  )

  const currentMargentDP = useMemo(
    () => calculateMargent(state.marketPriceDP, state.entryPriceDP, state.IVADP),
    [state.marketPriceDP, state.entryPriceDP, state.IVADP],
  )

  const currentPriceSDP = useMemo(
    () => calculatePrice(state.entryPriceSDP, state.margentSDP, state.IVASDP),
    [state.entryPriceSDP, state.margentSDP, state.IVASDP],
  )

  const currentProfitSDP = useMemo(
    () => calculateProfit(currentPriceSDP, state.entryPriceSDP, state.IVASDP),
    [currentPriceSDP, state.entryPriceSDP, state.IVASDP],
  )

  const currentMargentSDPProfit = useMemo(
    () => calculateMargentProfit(state.marketPriceSDP, state.entryPriceSDP, state.IVASDP),
    [state.marketPriceSDP, state.entryPriceSDP, state.IVASDP],
  )

  const currentMargentSDP = useMemo(
    () => calculateMargent(state.marketPriceSDP, state.entryPriceSDP, state.IVASDP),
    [state.marketPriceSDP, state.entryPriceSDP, state.IVASDP],
  )

  const currentPriceCP = useMemo(
    () => calculatePrice(state.entryPriceCP, state.margentCP, state.IVACP),
    [state.entryPriceCP, state.margentCP, state.IVACP],
  )

  const currentProfitCP = useMemo(
    () => calculateProfit(currentPriceCP, state.entryPriceCP, state.IVACP),
    [currentPriceCP, state.entryPriceCP, state.IVACP],
  )

  const currentMargentCPProfit = useMemo(
    () => calculateMargentProfit(state.marketPriceCP, state.entryPriceCP, state.IVACP),
    [state.marketPriceCP, state.entryPriceCP, state.IVACP],
  )

  const currentMargentCP = useMemo(
    () => calculateMargent(state.marketPriceCP, state.entryPriceCP, state.IVACP),
    [state.marketPriceCP, state.entryPriceCP, state.IVACP],
  )

  const changeIVAHandler = (e) => {
    const value = e.target.value
    setState((prevState) => ({
      ...prevState,
      IVA: value,
      IVADP: prevState.IVADP === prevState.IVA || prevState.IVADP === 0 || prevState.IVADP === '' ? value : prevState.IVADP,
      IVASDP: prevState.IVASDP === prevState.IVA || prevState.IVASDP === 0 || prevState.IVASDP === '' ? value : prevState.IVASDP,
      IVACP: prevState.IVACP === prevState.IVA || prevState.IVACP === 0 || prevState.IVACP === '' ? value : prevState.IVACP,
    }))
  }

  const changeEntryPriceHandler = (e) => {
    const value = e.target.value
    setState((prevState) => ({
      ...prevState,
      entryPrice: value,
      entryPriceDP:
        prevState.entryPriceDP === prevState.entryPrice || prevState.entryPriceDP === 0 || prevState.entryPriceDP === ''
          ? value
          : prevState.entryPriceDP,
      entryPriceSDP:
        prevState.entryPriceSDP === prevState.entryPrice || prevState.entryPriceSDP === 0 || prevState.entryPriceSDP === ''
          ? value
          : prevState.entryPriceSDP,
      entryPriceCP:
        prevState.entryPriceCP === prevState.entryPrice || prevState.entryPriceCP === 0 || prevState.entryPriceCP === ''
          ? value
          : prevState.entryPriceCP,
    }))
  }

  const price = {
    marketPrice: state.spanSwitcher ? parseFloat(currentPrice) : parseFloat(state.marketPrice),
    marketPriceDP: state.spanSwitcher ? parseFloat(currentPriceDP) : parseFloat(state.marketPriceDP),
    marketPriceSDP: state.spanSwitcher ? parseFloat(currentPriceSDP) : parseFloat(state.marketPriceSDP),
    marketPriceCP: state.spanSwitcher ? parseFloat(currentPriceCP) : parseFloat(state.marketPriceCP),
    ourPrice: parseFloat(state.entryPrice),
    ourPriceCP: parseFloat(state.entryPriceCP),
    ourPriceSDP: parseFloat(state.entryPriceSDP),
    ourPriceDP: parseFloat(state.entryPriceDP),
    IVA: state.IVA,
    IVADP: state.IVADP,
    IVASDP: state.IVASDP,
    IVACP: state.IVACP,
    MB: parseFloat(state.spanSwitcher ? state.margent : currentMargent),
    DP: parseFloat(state.spanSwitcher ? state.margentDP : currentMargentDP),
    SDP: parseFloat(state.spanSwitcher ? state.margentSDP : currentMargentSDP),
    CP: parseFloat(state.spanSwitcher ? state.margentCP : currentMargentCP),
    SPOPrice: state.spanSwitcher ? parseFloat(state.marketPriceSPO) : parseFloat(state.marketPrice),
  }

  useEffect(() => {
    if (save) {
      console.log(price, 'into save')
      changeCurrentProduct(price)
    }
  }, [save])

  async function saveProduct() {
    const prod = { ...currientProduct, ...price }
    delete prod._id
    await updateProduct({
      data: prod,
      _id: product._id,
    })
    logAction('Update product', {
      dif: findDifferences(product, prod),
      user: {
        ...usr,
        actions: {},
      },
    })
    dispatch(mainApi.util.invalidateTags(['Product']))
    dispatch(changeSave(false))
    setSaveModal(false)
  }

  return loading ? (
    <CircularProgress color='secondary' />
  ) : (
    <div className='catalogPrice'>
      <div className='catalogPrice__header'>
        <div
          className={`catalogPrice__header__btn ${state.standart && 'active'}`}
          onClick={() => setState((prevState) => ({ ...prevState, standart: true }))}
        >
          Standart
        </div>
        <div
          className={`catalogPrice__header__btn ${!state.standart && 'active'}`}
          onClick={() => setState((prevState) => ({ ...prevState, standart: false }))}
        >
          Calculator
        </div>
        <div
          className={`catalogPrice__header__btn ${!state.spoActive && 'active'}`}
          onClick={() => setState((prevState) => ({ ...prevState, spoActive: !prevState.spoActive }))}
        >
          SPO
        </div>
      </div>
      <div className='catalogPrice__body'>
        <div className='catalogPrice__body__title'>
          <div className='catalogPrice__body__title__box'>
            <div
              className={`catalogPrice__body__title__box__item ${state.spanSwitcher && 'active'}`}
              onClick={() => setState((prevState) => ({ ...prevState, spanSwitcher: true }))}
            >
              %
            </div>
            <div
              className={`catalogPrice__body__title__box__item  ${!state.spanSwitcher && 'active'}`}
              onClick={() => setState((prevState) => ({ ...prevState, spanSwitcher: false }))}
            >
              &euro;
            </div>
          </div>
          <div className='catalogPrice__body__title__item'>CP</div>
          <div className='catalogPrice__body__title__item'>DP</div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__title__item'>
            SDP
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__title__item'>
            MBP
          </div>
          <div style={state.spoActive ? { display: 'none' } : {}} className='catalogPrice__body__title__item'>
            SPO
          </div>
        </div>
        <div className='catalogPrice__body__item'>
          <div
            className='catalogPrice__body__item__item title'
            style={{ background: !state.spanSwitcher && '#CBDCCB', color: !state.spanSwitcher && '#FFFFFF' }}
          >
            Price
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              disabled={state.spanSwitcher}
              onChange={(e) => setState((prevState) => ({ ...prevState, marketPrice: e.target.value }))}
              type='string'
              value={state.spanSwitcher ? currentPrice : state.marketPrice}
              defaultValue={state.marketPrice}
              id='standard-basic'
              size='small'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              disabled={state.spanSwitcher}
              value={state.spanSwitcher ? currentPriceDP : state.marketPriceDP}
              defaultValue={state.marketPriceDP}
              onChange={(e) => setState((prevState) => ({ ...prevState, marketPriceDP: e.target.value }))}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled={state.spanSwitcher}
              value={state.spanSwitcher ? currentPriceSDP : state.marketPriceSDP}
              defaultValue={state.marketPriceSDP}
              onChange={(e) => setState((prevState) => ({ ...prevState, marketPriceSDP: e.target.value }))}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled={state.spanSwitcher}
              value={state.spanSwitcher ? currentPriceCP : state.marketPriceCP}
              defaultValue={state.marketPriceCP}
              onChange={(e) => setState((prevState) => ({ ...prevState, marketPriceCP: e.target.value }))}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.spoActive ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled={state.spanSwitcher}
              value={state.spanSwitcher ? currentPriceSPO : parseFloat(parseFloat(state.marketPriceSPO).toFixed(2))}
              defaultValue={state.marketPriceSPO}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
        </div>
        <div className='catalogPrice__body__item'>
          <div className='catalogPrice__body__item__item title' style={{ background: '#CBDCCB', color: '#FFFFFF' }}>
            Entry price
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              onChange={(e) => setState((prevState) => ({ ...prevState, entryPriceCP: e.target.value }))}
              value={state.entryPriceCP}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              value={state.entryPriceDP}
              onChange={(e) => setState((prevState) => ({ ...prevState, entryPriceDP: e.target.value }))}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              value={state.entryPriceSDP}
              onChange={(e) => setState((prevState) => ({ ...prevState, entryPriceSDP: e.target.value }))}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              value={state.entryPriceCP}
              onChange={(e) => setState((prevState) => ({ ...prevState, entryPriceCP: e.target.value }))}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.spoActive ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              onChange={(e) => setState((prevState) => ({ ...prevState, entryPriceSPO: e.target.value }))}
              value={state.entryPriceSPO}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
        </div>
        <div className='catalogPrice__body__item'>
          <div className='catalogPrice__body__item__item title'>Profit</div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              disabled
              onChange={(e) => setState((prevState) => ({ ...prevState, profitCP: e.target.value }))}
              value={state.spanSwitcher ? currentProfitCP : currentMargentCPProfit}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              disabled
              onChange={(e) => setState((prevState) => ({ ...prevState, profitDP: e.target.value }))}
              value={state.spanSwitcher ? currentProfitDP : currentMargentDPProfit}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled
              onChange={(e) => setState((prevState) => ({ ...prevState, profitSDP: e.target.value }))}
              value={state.spanSwitcher ? currentProfitSDP : currentMargentSDPProfit}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
                '& fieldset': { border: 'none' },
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled
              onChange={(e) => setState((prevState) => ({ ...prevState, profit: e.target.value }))}
              value={state.spanSwitcher ? currentProfit : currentMargentProfit}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.spoActive ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled
              onChange={(e) => setState((prevState) => ({ ...prevState, profitSPO: e.target.value }))}
              value={state.spanSwitcher ? currentProfitSPO : currentMargentProfitSPO}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
        </div>
        <div className='catalogPrice__body__item'>
          <div
            style={{ background: state.spanSwitcher && '#CBDCCB', color: state.spanSwitcher && '#FFFFFF' }}
            className='catalogPrice__body__item__item title'
          >
            Margen
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              disabled={!state.spanSwitcher}
              onChange={(e) => setState((prevState) => ({ ...prevState, margentCP: e.target.value }))}
              value={state.spanSwitcher ? state.margentCP : currentMargentCP}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              disabled={!state.spanSwitcher}
              onChange={(e) => setState((prevState) => ({ ...prevState, margentDP: e.target.value }))}
              value={state.spanSwitcher ? state.margentDP : currentMargentDP}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled={!state.spanSwitcher}
              onChange={(e) => setState((prevState) => ({ ...prevState, margentSDP: e.target.value }))}
              value={state.spanSwitcher ? state.margentSDP : currentMargentSDP}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled={!state.spanSwitcher}
              onChange={(e) => setState((prevState) => ({ ...prevState, margent: e.target.value }))}
              value={state.spanSwitcher ? state.margent : currentMargent}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.spoActive ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              disabled={!state.spanSwitcher}
              onChange={(e) => setState((prevState) => ({ ...prevState, margentSPO: e.target.value }))}
              value={state.spanSwitcher ? state.margentSPO : currentMargentSPO}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
        </div>
        <div className='catalogPrice__body__item'>
          <div style={{ background: '#CBDCCB', color: '#FFFFFF' }} className='catalogPrice__body__item__item title'>
            IVA
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              onChange={(e) => setState((prevState) => ({ ...prevState, IVACP: e.target.value }))}
              value={state.IVACP}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div className='catalogPrice__body__item__item'>
            <TextField
              onChange={(e) => setState((prevState) => ({ ...prevState, IVADP: e.target.value }))}
              value={state.IVADP}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              onChange={(e) => setState((prevState) => ({ ...prevState, IVASDP: e.target.value }))}
              value={state.IVASDP}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.standart ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              onChange={changeIVAHandler}
              value={state.IVA}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
          <div style={state.spoActive ? { display: 'none' } : {}} className='catalogPrice__body__item__item'>
            <TextField
              onChange={changeIVAHandler}
              value={state.IVA}
              id='standard-basic'
              size='small'
              type='string'
              variant='standard'
              InputProps={{
                disableUnderline: true,
              }}
              sx={{
                textTransform: 'uppercase',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'bold',
                fontWeight: '700',
                lineHeight: 'normal',
                textAlign: 'center',
                maxWidth: 115,
              }}
            />
          </div>
        </div>
      </div>
      <div className='catalogPrice__footer'>
        <div style={{ display: 'flex', width: '30%', justifyContent: 'center', gap: '10px' }}>
          <div onClick={openSaveModal} className='catalogPrice__footer__btn'>
            Save
          </div>
          <Modal open={saveModal} onClose={() => setSaveModal(false)}>
            {isLoading ? (
              <CircularProgress
                color='success'
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 300,
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography id='modal-modal-title' variant='h6' component='h2'>
                  Save
                </Typography>
                <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                  Do you want to save?
                </Typography>
                <Button onClick={closeSaveModal} sx={{ mt: 2 }} variant='contained'>
                  No
                </Button>
                <Button onClick={saveProduct} sx={{ mt: 2, ml: 2 }} variant='contained' color='success'>
                  Yes
                </Button>
              </Box>
            )}
          </Modal>
          <div
            onClick={() => {
              setReset(true)
              setTimeout(() => {
                setReset(false)
              }, 500)
            }}
            className='catalogPrice__footer__btn'
          >
            Reset
          </div>
        </div>
        <div style={{ display: 'flex', width: '30%', justifyContent: 'center', gap: '10px' }}>
          <div className='catalogPrice__footer__btn'>Copy</div>
          <div className='catalogPrice__footer__btn'>Delete</div>
        </div>
      </div>
      {success && (
        <div style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
          <Alert variant='filled' severity='success'>
            Save success
          </Alert>
        </div>
      )}
      {error && (
        <div style={{ position: 'fixed', bottom: '10px', right: '10px' }}>
          <Alert variant='filled' severity='error'>
            Something went wrong. Try again.
          </Alert>
        </div>
      )}
    </div>
  )
}
