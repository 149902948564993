import React, { useState } from 'react'
import { ReactComponent as Copy } from '../assets/svg/Copy.svg'
import { ReactComponent as Export } from '../assets/svg/Export.svg'
import { ReactComponent as Status } from '../assets/svg/Status.svg'
import { ReactComponent as NewOrder } from '../assets/svg/NewOrder.svg'
import { ReactComponent as Truck } from '../assets/svg/Truck.svg'
import { ReactComponent as Delete } from '../assets/svg/Delete.svg'

import { useDispatch } from 'react-redux'
import { mainApi } from '../store/services/mainApi'

import { Box, Button, Modal, TextField } from '@mui/material'
import { logAction } from './logsAction/logService'

export function renderModal(key, selectedItems, setSelectedItems) {
  const [deleteProduct] = mainApi.useDeleteProductMutation()
  const dispatch = useDispatch()

  const [dummyModal, setDummyModal] = useState(false)
  const [password, setPassword] = useState('')

  const handleDeleteItems = (e) => {
    if (password === '489') {
      deleteProductHandle(selectedItems, deleteProduct, dispatch, setSelectedItems)
      e.stopPropagation()
      setDummyModal(false)
    } else {
      alert('Wrong password')
    }
  }

  switch (key) {
    case 'Copy':
      return (
        <div className='tableModal__body__item'>
          <Copy width={30} height={30} />
          <p>Copy</p>
        </div>
      )
    case 'Export':
      return (
        <div onClick={() => exportProductsHndle(selectedItems, setSelectedItems)} className='tableModal__body__item'>
          <Export width={30} height={30} />
          <p>Export</p>
        </div>
      )
    case 'Status':
      return (
        <div className='tableModal__body__item'>
          <Status width={30} height={30} />
          <p>Status</p>
        </div>
      )
    case 'New product':
      return (
        <div className='tableModal__body__item'>
          <NewOrder width={30} height={30} />
          <p>New product</p>
        </div>
      )
    case 'Add Route':
      return (
        <div className='tableModal__body__item'>
          <Truck width={30} height={30} />
          <p>Add Route</p>
        </div>
      )
    case 'Delete':
      return (
        <div onClick={(e) => setDummyModal(true)} className='tableModal__body__item'>
          <Delete width={30} height={30} />
          <p className='red'>Delete</p>
          <Modal open={dummyModal} onClose={() => setDummyModal(false)}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 250,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p>Are you sure?</p>
              <TextField
                type='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                id='outlined-basic'
                label='Password'
                variant='outlined'
              />
              <Box>
                <Button variant='contained' color='success' sx={{ m: 2 }} onClick={handleDeleteItems}>
                  Yes
                </Button>
                <Button
                  variant='contained'
                  color='error'
                  sx={{ m: 2 }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setDummyModal(false)
                  }}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      )
    case 'Total Items Price':
      return (
        <div className='tableModal__body__item'>
          <p className='black'>Total Items Price</p>
          <p className='green'>{totalItemsPrice(selectedItems)} €</p>
        </div>
      )
    case 'Total Market Price':
      return (
        <div className='tableModal__body__item'>
          <p className='black'>Total Market Price</p>
          <p className='green'>{totalMarketPrice(selectedItems)} €</p>
        </div>
      )
  }
}

const totalMarketPrice = (items) => {
  let result = 0
  items.forEach((item) => {
    if (item.marketPriceCP) {
      result += parseFloat(item?.marketPriceCP)
    } else {
      result += 0
    }
  })
  return result.toFixed(2)
}

const totalItemsPrice = (items) => {
  let result = 0
  items.forEach((item) => {
    if (item.ourPrice) {
      result += parseFloat(item?.ourPrice)
    } else {
      result += 0
    }
  })
  return result.toFixed(2)
}

const deleteProductHandle = async (selectedItems, deleteProduct, dispatch, setSelectedItems) => {
  await Promise.all(
    selectedItems.map(async (el) => {
      await deleteProduct(el._id.toString()) // Pass the _id value as a string
    }),
  )
  const usr = JSON.parse(localStorage.getItem('acc'))
  logAction('Delete products', {
    productIds: selectedItems.map((item) => item._id),
    user: {
      ...usr,
      actions: {},
    },
  })
  dispatch(mainApi.util.invalidateTags(['Products']))
  setSelectedItems([])
}

const exportProductsHndle = async (selectedItems, setSelectedItems) => {
  const selectedItemIds = selectedItems.map((item) => item._id)
  window.open('https://tpomobi.shop/exportProductAdmin?_ids=' + selectedItemIds.join(','), '_blank')
  setSelectedItems([])
}
