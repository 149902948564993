import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {Box, Checkbox, Link, Typography, Switch} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";

import React, { useState, useMemo, useEffect, useCallback } from 'react'



const JuiceProductionAccordion = ({fakeRows, selectedItems, allItemsArray, handleTopLevelSelect, handleAllItemsSelect, setSwitchChecker, switchChecker, rows, openRows, toggleRowOpen, handleNestedSelect}) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                // Checked if all nested orders are selected
                                checked={selectedItems.length == allItemsArray.length}
                                onChange={(event) => handleAllItemsSelect()}
                            />
                        </TableCell>
                        <TableCell>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignContent: 'center',
                                justifyContent: 'center',
                                textAlign: 'left'
                            }}>
                                Group
                                <Switch checked={switchChecker} onChange={(event) => {setSwitchChecker(prev => !prev)}} />

                            </div>
                        </TableCell>
                        <TableCell>Elemento</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Type</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fakeRows.map((item) => (
                        <React.Fragment key={item._id}>
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell>
                                    <Checkbox
                                        // Checked if all nested orders are selected
                                        checked={item.orders.every((historyRow) =>
                                            selectedItems.some(i =>
                                                (i._id + i.items._id) === (historyRow._id + historyRow.items._id)
                                            )
                                        )}
                                        onChange={(event) => handleTopLevelSelect(event, item)}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => toggleRowOpen(item.sku)}
                                    >
                                        {openRows[item._id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {item.name.replace("Zumos Frescos", "").replace("bot/plast.", "")}
                                </TableCell>
                                <TableCell align="right">{item.totalQuantity}</TableCell>
                                <TableCell align="right">{parseFloat(item.totalPrice).toFixed(2) + "€"}</TableCell>
                                <TableCell align="right">{item.sku.includes("plast") ? "Plast" : "Crystal"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={openRows[item.sku]} timeout="auto" unmountOnExit>
                                        <Box sx={{ margin: 1 }}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                {item.name.replace("Zumos Frescos", "").replace("bot/plast.", "")}
                                            </Typography>
                                            <Table size="small" aria-label="purchases">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell />
                                                        <TableCell>Client</TableCell>
                                                        <TableCell align="right">Order ID</TableCell>
                                                        <TableCell align="right">Delivery Date</TableCell>
                                                        <TableCell align="right">Amount</TableCell>
                                                        <TableCell align="right">Total price ($)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {item.orders.map((historyRow) => (
                                                        <TableRow key={historyRow._id + historyRow.items._id}>
                                                            <TableCell>
                                                                <Checkbox
                                                                    checked={selectedItems.some(
                                                                        i => (i._id + i.items._id) === (historyRow._id + historyRow.items._id)
                                                                    )}
                                                                    onChange={(event) => handleNestedSelect(event, historyRow)}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{historyRow.tradeName || historyRow.userAcc.tradeName || historyRow.userAcc.name}</TableCell>
                                                            <TableCell align="right">
                                                                <Link target="_blank" href={"/admin/orders/" + historyRow._id}>
                                                                    {historyRow._id.slice(-6)}
                                                                </Link>
                                                            </TableCell>
                                                            <TableCell align="right">{historyRow.deliveryDate + " " + historyRow.deliveryTime}</TableCell>
                                                            <TableCell align="right">
                                                                {historyRow.items.quantity + " " + historyRow.items.unit}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {parseFloat(historyRow.items.quantity * historyRow.items.marketPriceCP).toFixed(2)  + "€"}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default JuiceProductionAccordion;